/* Web */
@media only screen and (min-width: 768px) {
  .category-container {
    position: relative;
    width: 96vw;
    height: min-content;
    padding: 0 0 4vh 0;
    background: #FFFFFF;
    border: 1px solid rgba(69, 120, 251, 0.17);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    margin: 0 0 1.7vh 0;
    overflow-y: hidden;

    .name-link {
      position: relative;
      width: calc(100% - 4vw);
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 3.3vh 2vw 1.2vh 1.5vw;

      .name {
        margin: 0;
        color: #4578FB;
        font-size: 1.2vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .icon {
          font-size: 2.5vw;
        }
      }

      .link {
        background: none;
        border: none;
        margin: 0;
        font-size: 1.1vw;
        font-weight: 200;
        color: #4578FB;
        text-decoration: none;
        cursor: pointer;
      }
    }

    .products-container {
      width: calc(100% - 2vw);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding: 0 1vw;

      .products {
        width: 84.7vw;
        display: flex;
        flex-direction: row;
        overflow-x: hidden;

        .add-container {
          border-left: 1px solid #F2F2F2;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          min-width: 12.1vw;
          height: 39vh;
          cursor: pointer;

          .add-icon {
            color: #4578FB;
            font-size: 1.7vw;
          }

          .add-message {
            color: #4578FB;
            font-size: 1.1vw;
            font-weight: 200;
            width: 5.5vw;
            text-align: center;
          }
        }
      }

      .arrow-button {
        background: none;
        border: none;
        cursor: pointer;

        .icon {
          color: #4577FB;
          font-size: 4.5vh;
        }
      }

      .none {
        opacity: .6;
      }

      .right {
        margin: 0 0 0 2vw;
      }

      .left {
        margin: 0 2vw 0 0;

        .icon {
          transform: rotate(180deg);
        }
      }
    }
  }

  .category-list-container {
    width: 100%;
    height: 18vh;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(69, 120, 251, 0.17);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: relative;
    cursor: pointer;

    p {
      color: #4578FB;
      text-align: center;
      font-size: 1.1vw;
      width: 70%;
      cursor: pointer;
    }
  }
}
