/* Web */
@media only screen and (min-width: 768px) {
  .stock-issues-view {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .5);
    z-index: 15;

    .stock-issues-container {
      position: absolute;
      bottom: 10vh;
      left: 32.5vw;
      width: 35vw;
      height: 70vh;
      background-color: white;
      border-radius: 2vw;
      z-index: 16;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      padding: 5vh 0;

      .close-icon {
        color: #4578FB;
        position: absolute;
        right: 2vw;
        top: 3vh;
        font-size: 2vw;
        cursor: pointer;
      }

      .title {
        color: #4578FB;
        font-size: 1.2vw;
        font-weight: 300;
        margin: 2vh 0 4vh 0;
        position: relative;
        width: 75%;
        text-align: center;
      }

      .partners {
        width: calc(85% - 2vw);
        max-height: 53vh;
        padding: 0 2vw 0 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        overflow-y: auto;
        position: relative;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #F1F1F150;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(69, 120, 251, .15);
          border-radius: 5px;
        }

        .partner-product {
          width: 100%;
          margin: 0 0 1vh 0;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          position: relative;

          .partner {
            width: 100%;
            text-align: center;
            font-size: 1.2vw;
            font-weight: 500;
            color: #7E7E7E;
          }

          .none-border {
            border: none !important;
          }

          .product {
            width: 100%;
            height: 15vh;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: flex-start;
            border-top: 1.5px solid #F2F2F2;
            position: relative;

            .left {
              width: 70%;
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              position: relative;

              img {
                width: 6.5vw;
                height: 6.5vw;
                padding: calc((15vh - 6.5vw) / 2) 0;
                margin: 0 .5vw 0 0;
              }

              .info {
                width: 60%;
                height: 8vh;
                padding: 3.5vh 0;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;

                .brand {
                  width: 100%;
                  text-align: left;
                  font-size: .9vw;
                  font-weight: 500;
                  color: #7E7E7E;
                  margin: 0;
                }

                .name {
                  width: 100%;
                  text-align: left;
                  font-size: .9vw;
                  font-weight: 300;
                  color: #7E7E7E;
                  margin: 0;
                }
              }
            }

            .right {
              width: 30%;
              height: 8vh;
              padding: 3.5vh 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              position: relative;

              .quantity {
                width: 100%;
                text-align: right;
                font-size: .9vw;
                font-weight: 500;
                color: #7E7E7E;
                margin: 0;
              }

              .new-quantity {
                width: 100%;
                text-align: right;
                font-size: .9vw;
                font-weight: 500;
                color: #4578FB;
                margin: 0;
              }

              .old-quantity {
                width: 100%;
                text-align: right;
                font-size: .9vw;
                font-weight: 300;
                text-decoration: line-through;
                color: #7E7E7E;
                margin: 0;
              }
            }
          }
        }
      }
    }

    .accept-button {
      position: absolute;
      bottom: 4vh;
      left: 30%;
      background-color: #4578FB;
      color: #FFFFFF;
      font-size: 1.1vw;
      font-weight: 300;
      width: 40%;
      height: 4vh;
      border: none;
      border-radius: .5vw;
      cursor: pointer;
    }
  }
}
