/* Web */
@media only screen and (min-width: 768px) {
  .sidebar-view {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    height: 87vh;
    width: 100vw;
    margin: auto;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9;

    .sidebar-container {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 30vw;
      height: 81vh;
      background-color: white;
      z-index: 10;
      display: flex;
      flex-direction: column;
      padding-top: 3vh;
      padding-bottom: 3vh;

      .close-icon {
        color: #4578FB;
        align-self: flex-end;
        margin-right: 2vw;
        margin-bottom: 2vh;
        font-size: 2vw;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          cursor: pointer;
          color: rgba(69, 119, 251, 0.5);
        }
      }

      .separator-1 {
        width: 14vw;
        height: .2vh;
        background-color: #E5E5E5;
        margin-left: 6vw;
        margin-top: 3vh;
        margin-bottom: 5vh;
      }

      .separator-2 {
        width: 14vw;
        height: .2vh;
        background-color: #E5E5E5;
        margin-left: 6vw;
        margin-top: 3vh;
        margin-bottom: 18vh;
      }

      .option {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 6vw;
        width: 22vw;
        height: 4vh;
        margin-bottom: 2vh;
        cursor: pointer;

        &:hover,
        &:focus,
        &:active {
          opacity: .5;
        }

        .icon {
          color: #7E7E7E;
          font-size: 3vh;
        }

        .text {
          color: #7E7E7E;
          font-size: calc(min(2.3vh, 1.3vw));
          font-weight: 300;
          margin-left: 1vw;
          cursor: pointer;
        }

        .link {
          text-decoration: none;
        }
      }

      .hide {
        display: none;
      }
      
      .version {
        color: #B7B7B7;
        position: absolute;
        left: 3vw;
        bottom: 2vh;
        font-size: 1.5vh;
      }
    }

    .out {
      position: absolute;
      bottom: 0;
      left: 30vw;
      height: 87vh;
      width: 70vw;
    }
  }
}
