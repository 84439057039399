.image-loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .spinner {
    color: #DADADA;
    width: 25% !important;
    height: 25% !important;
  }

  img {
    height: 100%;
    width: 100%;
  }

  .hide {
    display: none;
  }
}