html {
  scroll-behavior: smooth;
}

html, body {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
}

p {
  cursor: default;
}

.no-scroll {
  position: fixed;
}

/* Web */
@media only screen and (min-width: 768px) {

  /* width */
  ::-webkit-scrollbar {
    width: .4vw;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #E5E5E5;
    border-radius: .5vw;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgb(185, 185, 185);
    border-radius: .5vw;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: rgb(170, 170, 170);
  }

  .App {
    .navbar-landing {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: transparent;
      position: absolute;
      z-index: 3;
      top: 0px;
      width: 87vw;
      padding: 6.5vh 5vw 0 6vw;

      .logo {
        width: 18vw;
        margin: 0;
      }

      .links {
        margin-top: 0.8vw;
        display: flex;
        flex-direction: row;
        align-items: center;

        .signup-link {
          text-decoration: none;
          color: #FFFFFF;
          font-weight: 400;
          font-size: 1.2vw;
          margin: .8vw 0 0 3vw;
          position: relative;
          cursor: pointer;

          &:hover {
            opacity: .7;
          }
        }

        .login-button {
          background: transparent;
          border: 2px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 50px;
          color: #FFFFFF;
          cursor: pointer;
          height: 3.2vw;
          margin-left: 2.5vw;
          width: 14vw;
          font-size: 1vw;
          margin-top: .8vw;
          position: relative;

          &:hover {
            background-color: rgba(255, 255, 255, 0.2);
          }
        }
      }
    }

    .logo-mobile {
      display: none;
    }

    #landing-container {
      height: 100vh;
      background: linear-gradient(105.89deg, #4577FB 0%, #4577FB 0%, #28C3FF 76.56%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .text-container {
        .title {
          color: #FFFFFF;
          font-size: 3vw;
          margin: 0 22vw;
          text-align: center;
          margin-bottom: 3vw;
        }

        .description {
          color: #FFFFFF;  
          font-size: 1.5vw;
          margin: 0 26vw;
          text-align: center;
        }
      }

      .links {
        display: none;
      }

      .stores-container {
        position: relative;
        z-index: 3;
        margin-top: 3.5vw;
        display: flex;
        /* Uncomment when AppStore */
        /* justify-content: space-between; */
        justify-content: center;
        width: 33vw;

        a {
          margin: 0 2vw;
        }

        .store-button {
          width: 15vw;
          margin: 0;
        }
      }

      .scroll-down {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: absolute;
        bottom: 1vw;

        p {
          margin: 0;
          color: #FFFFFF;
          font-weight: 400;
        }

        a {
          .icon {
            color: #FFFFFF;
            font-size: 6vh;
            transform: rotate(90deg)
          }
        }
      }
    }

    #phones {
      margin: 0 0 4vw;
      text-align: center;

      .scroll-fix {
        width: 100%;
        height: 4vw;
      }  

      .title {
        font-size: 2vw;
        color: #4577FB;
        font-weight: 600;
        margin: 0 0 2vw;
      }

      .title-mobile {
        display: none;
      }

      #value-image-web {
        width: 90%;
      }
  
      #value-image-mobile {
        display: none;
      }
    }

    .store-container {
      position: relative;
      text-align: center;

      #store-image-web {
        width: 100%;
      }

      .message-container {
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);

        #title{
          font-size: 2.5vw;
          color: #FFFFFF;
          font-weight: 500;
        }
  
        #message{
          font-size: 1.5vw;
          color: #FFFFFF;
          font-weight: 400;
          margin: .7vw 0;
        }

        #message-web{
          font-size: 1.5vw;
          color: #FFFFFF;
          font-weight: 400;
          margin: .7vw 0;
        }

        #message-mobile{
          display: none;
        }
      }
    }

    #text-mobile {
      display: none;
    }
  
    #text-web {
      padding: 10vw 0vw;
      position: relative;
      text-align: center;

      .bubble {
        width: 22vw;
        height: 22vw;
        background-color: rgba(69, 119, 251, 0.05);
        border-radius: 12vw;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 39vw;

        .inner-contact-container {
          margin-left: 4vw;
          display: flex;
          flex-direction: column;

          #p-text1 {
            background: linear-gradient(#4577FB, #28C3FF);
            color:transparent;
            -webkit-background-clip: text;
            background-clip: text;
            font-size: 3.5vw;
            font-weight: 700;
            margin: 0;
            text-align: center;
            width: 60vw;
            padding-right: 1vw;
          }

          #p-text2 {
            background: linear-gradient(#4577FB, #28C3FF);
            color:transparent;
            -webkit-background-clip: text;
            background-clip: text;
            font-size: 3.5vw;
            font-weight: 700;
            margin: 0;
            text-align: center;
            width: 60vw;
          }
        }
      }
    }

    .partners-container-landing {
      position: relative;
      text-align: center;

      #partners-image-web {
        width: 100%;
      }

      .message-container {
        position: absolute;
        top: 47%;
        left: 50%;
        transform: translate(-50%, -50%);

        #title{
          font-size: 2.5vw;
          color: #FFFFFF;
          font-weight: 500;
        }

        #message{
          font-size: 1.5vw;
          color: #FFFFFF;
          font-weight: 400;
          margin: .7vw 0;
        }
      }
    }

    #contact-us {
      padding: 10vw 0vw;
      position: relative;

      .bubble {
        width: 96vw;
        height: 26vw;
        background-color: rgba(69, 119, 251, 0.05);
        border-top-right-radius: 10vw;
        border-bottom-right-radius: 10vw;
        display: flex;
        justify-content: center;
        align-items: center;

        .inner-contact-container {
          margin-left: 4vw;
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            color: #4577FB;
            font-size: 3vw;
            font-weight: 700;
            margin: 0;
            text-align: center;
            margin-bottom: 2vw;
          }

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            text-decoration: none;
            background: linear-gradient(91.87deg, #4577FB 0%, #28C3FF 118.92%);
            width: 20vw;
            height: 5vw;
            border-radius: 2vw;
            border-color: transparent;
            border-style: none;
            font-size: 2.3vw;

            &:hover,
            &:active,
            &:focus {
              cursor: pointer;
              background: linear-gradient(91.87deg, rgba(70, 118, 251, 0.8) 0%, rgba(40, 195, 255, 0.8) 118.92%);
            }

            p {
              color: #FFFFFF;
              font-size: 2vw;
              font-weight: 500;
              margin: 0;
              cursor: pointer;
            }
          }
        }
      }

      .cell-phone {
        z-index: 1;
        width: 100vw;
        position: absolute;
        bottom: 0;
        right: 0;
      }
    }

    .footer {
      position: relative;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      height: 60vh;
      background: linear-gradient(105.89deg, #4577FB 0%, #4577FB 0%, #28C3FF 76.56%);
      padding: 0 4vw;

      .wings-footer-web {
        position: absolute;
        top: 0;
        right: 0;
        width: 48vw;
      }
    
      .wings-footer-mobile {
        display: none;
      }

      .go-top {
        position: absolute;
        z-index: 5;
        top: 1vw;
        right: 2vw;
        padding: 2vw;

        .icon {
          color: #FFFFFF;
          font-size: 7vh;
          transform: rotate(270deg)
        }
      }

      .footer-info-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: left;
        margin-bottom: 1vw;

        .footer-logo {
          width: 18vw;
          margin-bottom: 2vw;
        }

        .footer-link {
          text-decoration: none;

          &:hover {
            cursor: pointer;
          }
        }

        .footer-info {
          font-size: 1.1vw;
          color: #FFFFFF;
          margin: .5vw 0 0;
          cursor: pointer;
        }

        .footer-icons-container {
          display: flex;
          flex-direction: row;
          position: relative;
          top: 2vw;

          a {
            margin-right: 1.5vw;

            .footer-icon {
              color: #FFFFFF;
              font-size: 2.5vw;
              cursor: pointer;
              position: relative;
            }
          }
        }
      }

      .footer-buttons-container {
        position: relative;
        display: flex;
        flex-direction: column-reverse;
        margin-bottom: 3.9vw;
      }

      a {
        img {
          width: 12vw;
          margin-top: 1.5vw;
        }
      }
    }
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .App {
    .navbar-landing {
      display: none;
    }

    #landing-container {
      width: 100vw;
      height: calc(100vh - 4vh);
      background: linear-gradient(105.89deg, #4577FB 0%, #4577FB 0%, #28C3FF 76.56%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 4vh;

      .text-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .logo-mobile {
          width: 70vw;
          margin-bottom: 8vh;
        }

        .title {
          color: #FFFFFF;
          font-size: 4.5vw;
          font-weight: 600;
          margin: 0 15vw;
          text-align: center;
          position: relative;
        }

        .description {
          display: none;
        }
      }

      .stores-container {
        margin: 5vh 0 8vh;
        display: flex;
        flex-direction: column;
        z-index: 3;
        position: relative;
  
        .store-button {
          margin-top: 3vh;
          width: 50vw;
        }
      }

      .links {
        display: flex;
        flex-direction: column;
        text-align: center;
        align-items: center;

        p {
          text-decoration: none;
          color: #FFFFFF;
          font-weight: 400;
          font-size: 4.5vw;
          position: relative;
          margin: 0;
        }

        button {
          background: transparent;
          border: 2px solid #FFFFFF;
          box-sizing: border-box;
          border-radius: 50px;
          color: #FFFFFF;
          height: 6vh;
          width: 44vw;
          font-size: 4.5vw;
          margin-top: 3vh;
          margin-bottom: 8vh;
          position: relative;
          cursor: pointer;
        }
      }

      .scroll-down {
        display: flex;
        flex-direction: column;
        align-items: center;

        p {
          display: none;
        }

        a {
          .icon {
            color: #FFFFFF;
            font-size: 7vh;
            transform: rotate(90deg)
          }
        }
      }
    }

    #phones {
      margin: 0 0 10vw;

      .title {
        display: none;
      }

      .title-mobile {
        font-size: 6vw;
        color: #4577FB;
        font-weight: 600;
        margin: 0;
        text-align: center;
      }
    }

    .scroll-fix {
      width: 100%;
      height: 6.5vh;
    }

    .store-container {
      #store-image-web {
        display: none;
      }

      .message-container {
        background-image: url('../assets/images/landing/store-mobile.jpg');
        height: 100vh;
        width: 100vw;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      #title{
        font-size: 7vw;
        color: #FFFFFF;
        font-weight: 700;
        width: 90vw;
        text-align: center;
      }

      #message{
        font-size: 5vw;
        color: #FFFFFF;
        font-weight: 400;
        width: 75vw;
        margin: 0 0 1.5vh 0;
        text-align: center;
      }

      #message-web{
        display: none;
      }

      #message-mobile{
        font-size: 5vw;
        color: #FFFFFF;
        font-weight: 400;
        margin: 0;
        text-align: center;
      }
    }

    #text-web {
      display: none;
    }

    #text-mobile {
      padding: 30vh 0;
      position: relative;

      .bubble {
        width: 55vw;
        height: 55vw;
        background-color: rgba(69, 119, 251, 0.05);
        border-radius: 30vw;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        left: 22vw;

        .inner-contact-container {
          margin-left: 4vw;
          display: flex;
          flex-direction: column;
          align-items: center;

          #p-text1 {
            background: linear-gradient(to right, #4577FB, #28C3FF);
            color:transparent;
            -webkit-background-clip: text;
            background-clip: text;
            font-size: 8vw;
            font-weight: 700;
            margin: 0;
            text-align: center;
            width: 70vw;
          }

          #p-text2 {
            background: linear-gradient(to right, #4577FB, #28C3FF);
            color:transparent;
            -webkit-background-clip: text;
            background-clip: text;
            font-size: 8vw;
            font-weight: 700;
            margin: 0;
            text-align: center;
            width: 80vw;
          }
        }
      }
    }

    .partners-container-landing .message-container {
      background-image: url('../assets/images/landing/partner-mobile.jpg');
      height: 100vh;
      width: 100vw;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      #title {
        font-size: 7vw;
        color: #FFFFFF;
        font-weight: 700;
        width: 55vw;
        text-align: center;
      }

      #message{
        font-size: 5vw;
        color: #FFFFFF;
        font-weight: 400;
        width: 60vw;
        margin: 0 0 1.5vh 0;
        text-align: center;
      }
    }

    #value-image-web,
    #partners-image-web {
      display: none;
    }

    #value-image-mobile {
      width: 100vw;
    }

    #contact-us {
      position: relative;
      margin: 8vh 0;

      .mobile-bubble {
        background-color: rgba(69, 119, 251, 0.05);
        border-radius: 25vw;
        height: 40vh;
        width: 28vw;
        position: absolute;
        left: 36vw;
      }

      .bubble {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 40vh;

        .inner-contact-container {
          display: flex;
          flex-direction: column;
          align-items: center;

          p {
            color: #4577FB;
            font-size: 7vw;
            font-weight: 700;
            margin: 0;
            text-align: center;
            margin-bottom: 5vw;
          }

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            text-decoration: none;
            background: linear-gradient(91.87deg, #4577FB 0%, #28C3FF 118.92%);
            width: 40vw;
            height: 7vh;
            border-radius: 8vw;
            border-color: transparent;
            border-style: none;

            &:hover,
            &:active,
            &:focus {
              cursor: pointer;
              background: linear-gradient(91.87deg, rgba(70, 118, 251, 0.8) 0%, rgba(40, 195, 255, 0.8) 118.92%);
            }

            p {
              color: #FFFFFF;
              font-size: 5vw;
              font-weight: 500;
              margin: 0;
              cursor: pointer;
            }
          }
        }
      }

      .cell-phone {
        display: none;
      }    
    }

    .footer {
      position: relative;
      height: 80vh;
      background: linear-gradient(105.89deg, #4577FB 0%, #4577FB 0%, #28C3FF 76.56%);
      padding: 0 10vw;

      .wings-footer-web {
        display: none;
      }
    
      .wings-footer-mobile {
        position: absolute;
        top: 0;
        right: 0;
        width: 100vw;
      }

      .go-top {
        position: absolute;
        z-index: 5;
        bottom: 5vh;
        right: 10vw;

        .icon {
          color: #FFFFFF;
          font-size: 7vh;
          transform: rotate(270deg)
        }
      }

      .footer-info-container {
        position: relative;
        z-index: 3;
        padding-top: 8vh;
        display: flex;
        flex-direction: column;
        text-align: left;

        .footer-logo {
          width: 50vw;
          margin-bottom: 3vh;
          margin-top: .3vh;
        }

        .footer-link {
          text-decoration: none;

          &:hover {
            cursor: pointer;
          }

          .footer-info {
            font-size: 4vw;
            color: #FFFFFF;
            margin: 1.2vh 0 0;
            cursor: pointer;
          }
        }

        .footer-icons-container {
          display: flex;
          flex-direction: row;

          .footer-icon {
            color: #FFFFFF;
            font-size: 7vw;
            margin-right: 5vw;
            margin-top: 4vh;
          }
        }
      }

      .footer-buttons-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-top: 4vh;

        a {
          text-decoration: none;
          color: transparent;
          cursor: pointer;
          z-index: 1;

          img {
            width: 36vw;
            margin-top: 1.7vh;
          }
        }
      }
    }
  } 
}
