/* Web */
@media only screen and (min-width: 768px) {
  .swiper-container {
    width: 98vw;
    height: 29vh;

    .center-banners {
      justify-content: center;
    }

    .swiper-pagination-fraction,
    .swiper-pagination-custom,
    .swiper-container-horizontal > .swiper-pagination-bullets {
      bottom: 15px;
      left: 0;
      width: 100%;
    }

    .swiper-wrapper {
      height: 26vh;

      .swiper-slide {
        text-align: center;
        width: 32.66vw !important;
    
        .banner-image {
          height: 21vh;
          filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
          border-radius: 20px;
          box-shadow: 0px 4px 4px 0px #000000 25%;
          cursor: pointer;
        }
      }
    }
  }
}
