/* Web */
@media only screen and (min-width: 768px) {
  html, body, #root {
    position: relative !important;
    overflow-x: hidden !important;
  }

  .catalog-container {
    width: 100vw;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    padding: 0 calc(100vw - 100%) 4vh 0;
    position: relative;
    overflow-x: hidden;

    .info-bar {
      width: calc(100% - 2.5vw);
      height: 15vh;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 2.5vw;

      .info1 {
        display: flex;
        flex-direction: row;

        .icon {
          color: #4577FB;
          font-size: 3.5vh;
          margin: 0 0 2.7vh 0;
        }

        .label-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .p-label {
            color: #4578FB;
            font-weight: 600;
            font-size: 1.2vw;
            width: 20vw;
            margin: 0 0 .3vh 0;
          }
        }

        .p-info {
          margin: 0;
          font-size: 1vw;
          font-weight: 300;
          color: #4578FB;
          width: 65vw;
          opacity: .7;
        }
      }

      .info2 {
        display: flex;
        flex-direction: row;
        position: relative;
        margin: 0 3.5vw 0 0;

        .distribution-options {
          display: flex;
          flex-direction: row;
          margin: 0 2vw 0 0;
          align-items: center;
          justify-content: center;

          .distribution-icon {
            position: relative;
            cursor: pointer;
            width: 1.5vw;
            height: 1.5vw;
            margin: 0 1vw 0 0;
        
            g {
              fill: #DADADA;
            }
          }

          .none-margin {
            margin: 0;
          }

          .selected {
            g {
              fill: #4578FB;
            }
          }

          .lines {
            margin: .3vh 0 0 0;
            position: relative;
            font-size: 2.5vw;
            cursor: pointer;
          }
        }

        .input-container {
          position: relative;

          .search-input {
            width: 13vw;
            height: 5vh;
            padding: 0 3vw 0 4vw;
            border: 2px solid #4578FB;
            border-radius: 3vw;
            color: #4578FB;
            font-size: 1.2vw;
  
            &::placeholder {
              color: #4578FB;
              opacity: .5;
              font-weight: 300;
              font-size: 1.2vw;
            }
          }
  
          .search-icon {
            position: absolute;
            top: 1.2vh;
            left: 1.3vw;
            font-size: 2vw;
            color: #4578FB;
            opacity: .5;
            cursor: pointer;
          }

          .close-icon {
            position: absolute;
            top: .7vh;
            right: 1.3vw;
            font-size: 2vw;
            color: #4578FB;
            opacity: .5;
            cursor: pointer;
          }
        }
      }
    }

    .search-grid {
      --partnes-grid-width: 96vw;
      width: var(--partnes-grid-width);
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      margin: 0 auto 4vh;
      overflow: hidden;
    }

    .categories-grid {
      display: grid;
      width: 96vw;
      grid-template-columns: repeat(6, 15.16vw);
      grid-gap: 1vw;
    }

    .modal {
      display: block;
      position: fixed;
      z-index: 25;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      background-color: rgba(0,0,0,0.6);

      .modal-content2 {
        background-color: #FFFFFF;
        margin: 27.5vh 37.5vw;
        width: 25vw;
        height: 45vh;
        overflow-y: scroll;
        border-radius: 2vw;
        position: relative;

        &::-webkit-scrollbar {
          display: none;
        }

        .modal-main2 {
          position: relative;
          z-index: 3;
          margin: 0 6vw;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .modal-title2 {
            margin: 0 0 1vw 0;
            font-size: 1.2vw;
            font-weight: 500;
            width: 18vw;
            color: #4577FB;
          }

          .modal-message {
            margin: 0 0 1vw 0;
            font-size: .9vw;
            font-weight: 300;
            width: 18vw;
            color: #4578FB;
          }

          .icon-face {
            margin: 0 0 3vh 0;
            font-size: 4.5vw;
            color: #4578FB;
          }

          .back {
            color: #4577FB;
            font-size: 1.2vw;
            font-weight: 300;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

   // Change: Delete this when payment methods are integrated
   .ctb-modal-view {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9;
    overflow: hidden;

    .ctb-modal-container {
      background-color: #FFFFFF;
      margin: 11.5vh 31vw;
      width: 29vw;
      height: calc(68vh - 2.7vw);
      padding: 0 4.5vw calc(9vh + 2.7vw) 4.5vw;
      border-radius: 2vw;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .title {
        color: #4578FB;
        margin: 7vh 0 3vh 0;
        font-size: 1.2vw;
        font-weight: 700;
        width: 50%;
        text-align: center;
      }

      .content {
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        &::-webkit-scrollbar {
          width: .3vw;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(69, 120, 251, 0.5);
        }

        &::-webkit-scrollbar-track {
          margin: 2vh 0;
          background: #E5E5E5;
        }

        .description {
          color: #B7B7B7;
          font-weight: 300;
          font-size: 1vw;
          text-align: center;
          margin: 0 0vw 5vh 0vw;
        }
  
        .element {
          margin: 2vh 0 0 0;

          .data {
            margin: 0;
            font-size: 1vw;
            font-weight: 300;
            color: #4578FB;
            display: inline;
          }

          .bold {
            font-weight: 500;
          }
        }
  
        .first {
          margin-top: 0;
        }
      }

      .button {
        width: 15vw;
        height: 2.7vw;
        background-color: #4578FB;
        border: none;
        border-radius: 15px;
        color: #FFFFFF;
        font-size: 1.1vw;
        font-weight: 500;
        padding: 0;
        cursor: pointer;
        position: absolute;
        bottom: 8vh;

        &:hover {
          opacity: .7;
        }
      }
    }
  }
}
