/* Web */
@media only screen and (min-width: 768px) {
  .sidebar-checkout-view {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9;
    overflow: hidden;

    .sidebar-checkout-container {
      position: absolute;
      bottom: 0;
      left: 58vw;
      width: 42vw;
      height: 96vh;
      background-color: white;
      z-index: 10;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 0 4vh 0;

      .close-icon {
        color: #4578FB;
        position: absolute;
        right: 2vw;
        top: 4vh;
        font-size: 2vw;
        cursor: pointer;

        &:hover {
          opacity: .5;
        }
      }

      .sidebar-back-container {
        position: absolute;
        right: 2vw;
        top: 4vh;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .icon {
          color: #4577FB;
          font-size: 3.5vh;
          transform: rotate(180deg);
          cursor: pointer;
        }

        p {
          color: #4578FB;
          font-size: 1vw;
          font-weight: 300;
          margin: 0 0 0 .3vw;
          cursor: pointer;
        }
      }

      .info-store {
        width: 36vw;
        height: calc(13vh - 1.5px);
        display: flex;
        flex-direction: row;
        align-items: center;
        border-bottom: 1.5px solid rgba(69, 120, 251, .5);
        margin: 0 0 2vh 0;

        .account-icon {
          font-size: 3vw;
          color: #4578FB;
        }

        .name {
          margin: 0 0 0 1.5vw;
          font-size: 1.2vw;
          font-weight: 500;
          color: #4578FB;
        }
      }

      .partners-container-credits {
        height: 65.1vh !important;
      }

      .partners-container {
        width: 39vw;
        height: 70.5vh;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #F1F1F150;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(69, 120, 251, .15);
          border-radius: 5px;
        }

        .none-border {
          border: none !important;
        }

        .info-partner {
          width: 36vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          position: relative;
          border-top: 1px dashed rgba(126, 126, 126, .5);
          padding: 2vh 0 3vh 0;

          .info1 {
            width: 100%;
            height: 4vh;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            .left {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              width: 60%;

              .icon {
                color: #4577FB;
                font-size: 3.5vh;
              }

              .name {
                margin: 0 0 0 .7vw;
                width: 80%;
                color: #4578FB;
                font-size: 1vw;
                font-weight: 500;
              }
            }

            .right {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              width: calc(40% - 1vw);
              padding: 0 1vw 0 0;
            }

            .spinner {
              color: #4578FB;
              width: 1.2vw !important;
              height: 1.2vw !important;
            }

            .mount {
              margin: 0;
              width: 100%;
              color: #4578FB;
              font-size: 1vw;
              font-weight: 500;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
            }
          }

          .info2 {
            width: 100%;
            height: 2.5vh;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;

            .left {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              width: calc(60% - 1.6vw);
              padding-left: 1.6vw;

              .icon {
                font-size: 1.1vw;
                color: #B7B7B7;
              }

              .info {
                margin: 0 0 0 .5vw;
                width: 80%;
                color: #B7B7B7;
                font-size: .8vw;
                font-weight: 300;
              }
            }

            .right {
              position: relative;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: flex-end;
              width: calc(40% - 1vw);
              padding: 0 1vw 0 0;

              .info {
                margin: 0;
                width: 100%;
                color: #B7B7B7;
                text-align: right;
                font-size: .8vw;
                font-weight: 300;
              }

              .old-price {
                margin: 0;
                width: 100%;
                color: #B7B7B7;
                text-align: right;
                font-size: .8vw;
                font-weight: 300;
                text-decoration: line-through;
              }

              .info-min {
                color: #FF2828;
                width: 50%;
                margin: 0;
                font-size: .8vw;
                font-weight: 300;
                text-align: right;
              }

              .info-discount {
                background-color: #4578FB;
                color: #FFFFFF;
                border-radius: .5vw;
                width: 45%;
                height: 90%;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: .8vw;
                cursor: pointer;
              }
            }
          }

          .products {
            width: 34vw;
            border: 1px solid rgba(69, 120, 251, 0.17);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
            border-radius: 1.2vw;
            padding: 0 1vw;
            margin: 1vh 0 0 0;
          }
        }

      }

      .credits-container {
        position: absolute;
        width: 42vw;
        bottom: 10vh;
        right: 0;
        box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.1);
        z-index: 15;
        background-color: #FFFFFF;
        display: flex;
        align-items: center;
        justify-content: center;

        .info-container {
          width: 36vw;
          height: 5.4vh;
          padding: 2.5vh 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          border-bottom: .1vh solid #DADADA;
          position: relative;
        }

        .left {
          width: 60%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;

          .gray {
            color: #7E7E7E;
            font-size: 1.05vw;
            font-weight: 500;
            margin: 0;
            text-align: left;
          }

          .blue {
            color: #4578FB;
            font-size: 1.05vw;
            font-weight: 500;
            margin: 0;
            text-align: left;
            display: flex;
            flex-direction: row;
            align-items: center;
            cursor: pointer;

            .icon {
              font-size: 1.1vw;
              margin: 0 0 0 .5vw;
            }
          }
        }

        .right {
          width: 60%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          justify-content: space-between;

          .info-loading {
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            .spinner {
              color: #4578FB;
              width: 1.5vw !important;
              height: 1.5vw !important;
            }
          }  

          .gray {
            color: #7E7E7E;
            font-size: 1.05vw;
            font-weight: 500;
            margin: 0;
            text-align: right;
          }

          .blue {
            color: #4578FB;
            font-size: 1.05vw;
            font-weight: 500;
            margin: 0;
            text-align: right;
          }
        }
      }

      .shadow {
        box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.1);
      }

      .total-container {
        position: absolute;
        width: 36vw;
        height: 8vh;
        bottom: 0;
        right: 0;
        z-index: 15;
        background-color: #FFFFFF;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 1vh 3vw;

        .info {
          height: 100%;
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .total-tax {
            margin: 0 0 .5vh 0;
            color: #7E7E7E;
            font-size: .9vw;
            font-weight: 300;
          }

          .total {
            margin: 0;
            color: #4578FB;
            font-size: 1.3vw;
            font-weight: 500;
          }
        }

        .info-loading {
          height: 100%;
          width: calc(50% - 2vw);
          padding: 0 0 0 2vw;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;

          .spinner {
            color: #4578FB;
            width: 2vw !important;
            height: 2vw !important;
          }
        }

        button {
          width: 40%;
          height: 52.5%;
          background-color: #4578FB;
          color: #FFFFFF;
          border: none;
          border-radius: 10px;
          font-size: 1vw;
          font-weight: 500;
          cursor: pointer;

          &:hover {
            opacity: .7;
          }
        }

        .none {
          opacity: .5;

          &:hover {
            opacity: .5;
          }
        }
      }

      .checkout-container {
        width: 39vw;
        height: 67.5vh;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        padding: 2vh 0 1vh 0;

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-track {
          background: #f1f1f150;
          border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(69, 120, 251, .15);
          border-radius: 5px;
        }

        .info1 {
          width: 34vw;
          height: 4vh;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          position: relative;
          margin: 0 0 2vh 0;

          .left {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            width: 75%;
            height: 4vh;

            .icon {
              color: #4577FB;
              font-size: 3.5vh;
            }

            .name {
              margin: 0 0 0 .7vw;
              width: 100%;
              color: #4578FB;
              font-size: 1vw;
              font-weight: 500;
            }
          }

          .right {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            width: 25%;
            height: 4vh;
            cursor: pointer;

            p {
              width: 35%;
              color: #4578FB;
              font-size: 1vw;
              font-weight: 300;
              cursor: pointer;
            }

            .icon {
              color: #4578FB;
              font-size: 1.5vw;
              cursor: pointer;
            }
          }
        }

        .inputs-container-checkout {
          border: 1px solid rgba(69, 120, 251, 0.17);
          box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
          border-radius: 1.2vw;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          width: 31vw;
          height: fit-content;
          padding: 1.5vw;
          margin: 0 0 2vh 0;
          position: relative;

          .title {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: 0 0 1vh 0;

            p {
              color: #4578FB;
              font-size: 1vw;
              font-weight: 500;
              margin: 0 0 0 .7vw;
            }

            .icon {
              color: #4578FB;
              font-size: 1.6vw;
            }
          }

          .address {
            width: 100%;
            color: #B7B7B7;
            margin: 0;
            font-size: 1vw;
            font-weight: 300;
            font-style: italic;
          }

          .input-container {
            width: 100%;
            height: fit-content;
            padding: .7vh 0;
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: flex-start;
            position: relative;
        
            ul {
              position: absolute;
              z-index: 50;
              top: calc(7vh + 5px);
              width: 100%;
              height: fit-content;
              list-style: none;
              border: 1px solid #DADADA;
              background-color: #FFFFFF;
              border-radius: 10px;
              padding: .5vh 0;
              margin: 0;
          
              .none-border {
                border: none;
              }
          
              li {
                position: relative;
                z-index: 60;
                width: calc(100% - 2vw);
                background-color: #FFFFFF;
                padding: 1vh 1vw;
                color: #4578FB;
                opacity: .7;
                border-top: 1.5px solid rgba(69, 120, 251, .5);
                cursor: pointer;
                display: flex;
                flex-direction: row;
                align-items: center;
          
                &:hover {
                  background-color: #4579fb40;
                }

                p {
                  margin: 0 0 0 .5vw;
                  font-size: 1vw;
                  font-weight: 300;
                }

                .icon {
                  font-size: 1.3vw;
                }
              }
            }

            .label {
              color: #B7B7B7;
              font-size: 1vw;
              font-style: italic;
              font-weight: 300;
              margin: 0 0 .5vh 0;
            }
            
            input {
              border: 1px solid #DADADA;
              border-radius: 10px;
              color: #4578FB;
              padding: 1vh 1vw 1vh 1.5vw;
              margin: 0;
              width: calc(100% - 2.5vw);
              height: 2vh;
              font-size: 1vw;
              font-weight: 300;
              opacity: .7;
          
              // Background color
              &:-webkit-autofill {
                transition: background-color 5000s ease-in-out 0s;
              }
              
              // Font styles
              &:-webkit-autofill::first-line {
                font-family: 'museo-sans';
                font-size: 3.5vw;
                color: #FFFFFF;
              }
            }

            .phone-input {
              padding: 1vh 1vw 1vh 3.9vw;
              width: calc(100% - 4.9vw);
              letter-spacing: .3px;
            }

            .phone-label-container {
              position: absolute;
              bottom: .7vh;
              left: 1.5vw;
              height: calc(4vh + 2px);
              display: flex;
              align-items: center;

              .phone-label {
                margin: 0;
                font-size: 1vw;
                font-weight: 300;
                color: #4578FB;
                opacity: .5;
                padding: 0 .3vw 0 0;
                border-right: .5px #4578FB solid;
              }
            }

            .not-valid {
              border: 1px solid #ff7373;
            }

            .help-icon {
              color: #4578FB;
              font-size: 1.3vw;
              position: absolute;
              bottom: 1.7vh;
              right: .8vw;
              cursor: pointer;
            }
          }

          .different-address-container {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;

            .icon {
              font-size: 2.2vw;
              color: #B7B7B7;
              cursor: pointer;
            }

            p {
              font-size: 1vw;
              font-weight: 300;
              color: #B7B7B7;
              margin: 0 0 0 .5vw;
            }

            .on {
              color: #4578FB !important;
            }
          }
        }
      }

      .none-container {
        width: 39vw;
        height: 70.5vh;
        overflow-y: auto;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .icon {
          color: #4578FB;
          font-size: 8vw;
        }

        p {
          color: #4578FB;
          font-size: 1.4vw;
          font-weight: 600;
          width: 40%;
          text-align: center;
          margin: 0;
        }
      }
    }

    .ask-address-modal {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, .5);
      z-index: 15;
  
      .modal-content {
        position: absolute;
        bottom: 35.5vh;
        left: 35vw;
        width: 30vw;
        height: 23vh;
        background-color: white;
        border-radius: 2vw;
        z-index: 16;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 6vh 0;

        p {
          margin: 0 0 1vh 0;
          color: #4578FB;
          font-size: 1.3vw;
          font-weight: 300;
          width: 70%;
          text-align: center;
        }

        .update-info-p {
          margin: 3vh 0 0 0;
        }

        .buttons {
          margin: 2vh 0 0 0;
          width: 80%;
          display: flex;
          flex-direction: row;
          position: relative;
          align-items: center;
          justify-content: space-between;

          button {
            width: 45%;
            height: 5vh;
            border: none;
            border-radius: .7vw;
            color: #FFFFFF;
            background-color: #4578FB;
            font-size: 1.4vw;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }

    .error-modal {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, .5);
      z-index: 15;
  
      .modal-content {
        position: absolute;
        bottom: 33vh;
        left: 37.5vw;
        width: 25vw;
        height: 30vh;
        background-color: white;
        border-radius: 2vw;
        z-index: 16;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 5vh 0;

        p {
          margin: 0 0 2vh 0;
          color: #4578FB;
          font-size: 1.3vw;
          font-weight: 300;
          width: 70%;
          text-align: center;
        }
        
        .title1 {
          margin: 0 0 3vh 0;
          font-weight: 500;
          width: 80% !important;
        }

        .title2 {
          width: 80% !important;
        }

        button {
          margin: 2vh 0 0 0;
          width: 25%;
          height: 4vh;
          border: none;
          border-radius: 5px;
          color: #FFFFFF;
          background-color: #4578FB;
          font-size: 1.1vw;
          font-weight: 300;
          cursor: pointer;
        }
      }
    }

    .out {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 100vh;
      width: 58vw;
    }
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__control {
    border: 1px solid #DADADA;
    border-radius: 10px;
    padding: 1vh 1vw 1vh 1.5vw;
    box-sizing: content-box;
    width: calc(100% - 2.5vw);
    opacity: .7;
    min-height: fit-content;
    box-shadow: 0 0 0 0px #FFFFFF;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__control:hover{
    border-color: #DADADA;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container.select__control--menu-is-open {
    border-color: #DADADA;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__control--is-focused {
    border-color: #DADADA;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__value-container {
    padding: 0;
    margin: 0;
    height: fit-content;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__single-value {
    display: none;
    color: #4578FB;
    font-size: 1.1vw;
    font-weight: 300;
    margin: 0;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__placeholder {
    color: #4578FB;
    font-size: 1.1vw;
    font-weight: 300;
    margin: 0;
    opacity: .5;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__option {
    color: #4578FB;
    font-size: 1.1vw;
    font-weight: 300;
    margin: 0;
    display: flex;
    align-items: flex-start;
    padding-left: 2vw;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__option--is-selected {
    background-color: #4578FB;
    color: #FFFFFF;
    font-size: 1.1vw;
    font-weight: 300;
    margin: 0;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__input {
    color: #4578FB;
    font-size: 1.1vw;
    font-weight: 300;
    margin: 0;
    align-self: start;
    border-radius: 0;
    width: 100%;
      
    input {
      opacity: 1 !important;
      width: 100% !important;
      border-radius: 0;
    }
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__indicators {
    height: 0;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .css-b8ldur-Input {
    margin: 0;
    padding: 0;
    font-size: 1.1vw;
    height: fit-content;
    width: 100%;
    border-radius: 0;
  }

  .sidebar-checkout-view .sidebar-checkout-container .checkout-container .inputs-container-checkout .input-container .select__menu-notice--no-options {
    color: #B7B7B7;
    font-size: 1.1vw;
    font-weight: 300;
    margin: 0;
  }

  .checkout-finish-view {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    height: 100vh;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9;
    overflow: hidden;

    .checkout-finish-container {
      background-color: #FFFFFF;
      margin: 14.5vh 35vw;
      width: 30vw;
      height: 70vh;
      padding: 1vh 0 0 0;
      overflow-y: scroll;
      border-radius: 2vw;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      &::-webkit-scrollbar {
        display: none;
      }

      .close-icon {
        color: #4578FB;
        position: absolute;
        right: 2vw;
        top: 4vh;
        font-size: 2vw;
        cursor: pointer;
      }

      .title {
        color: #4578FB;
        margin: 8vh 0 5vh 0;
        font-size: 1.2vw;
        font-weight: 700;
        width: 50%;
        text-align: center;
      }

      .information-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0 5vh 0;

        .icon {
          color: #4578FB;
          font-size: 3vw;
        }

        .information {
          color: #4578FB;
          margin: 10vh 0 2vh 0;
          font-size: 1.2vw;
          font-weight: 500;
          width: 70%;
          text-align: center;
          margin: .8vh 0;
        }

        .information2 {
          color: #4578FB;
          margin: 10vh 0 2vh 0;
          font-size: 1vw;
          font-weight: 500;
          width: 70%;
          text-align: center;
          margin: .8vh 0;
        }

        .name {
          color: #B7B7B7;
          margin: 10vh 0 2vh 0;
          font-size: 1vw;
          font-weight: 300;
          width: 50%;
          text-align: center;
          margin: 0;
        }
      }

      .specific-information {
        width: 15vw;
        height: 2.7vw;
        background-color: #4578FB;
        border: none;
        border-radius: 10px;
        color: #FFFFFF;
        font-size: 1.1vw;
        font-weight: 500;
        padding: 0;
        cursor: pointer;
        position: absolute;
        bottom: 9vh;
      }
    }
  }

  // Change: Delete this when payment methods are integrated
  .don-pancho-view {
    position: fixed;
    bottom: 0;
    left: 0;
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .5);
    z-index: 9;
    overflow: hidden;

    .don-pancho-container {
      background-color: #FFFFFF;
      margin: 11.5vh 31vw;
      width: 28vw;
      height: calc(68vh - 2.7vw);
      padding: 0 5vw calc(9vh + 2.7vw) 5vw;
      border-radius: 2vw;
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      .title {
        color: #4578FB;
        margin: 7vh .7vw 3vh .7vw;
        font-size: 1.2vw;
        font-weight: 700;
        width: 50%;
        text-align: center;
      }

      .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: .3vw;
        }

        &::-webkit-scrollbar-thumb {
          background: rgba(69, 120, 251, 0.5);
        }

        &::-webkit-scrollbar-track {
          margin: 2vh 0;
          background: #E5E5E5;
        }

        .description {
          color: #B7B7B7;
          font-weight: 300;
          font-size: 1vw;
          text-align: center;
          margin: 0 0 4vh 0;
        }
  
        .label {
          color: #4577FB;
          font-weight: 400;
          font-size: 1vw;
          margin: 0 0 .2vh 0,
        }
  
        .information {
          color: #4577FB;
          font-weight: 600;
          font-size: 1vw;
          margin: 0 0 1.5vh 0,
        }
  
        .no-space {
          margin: 0 0 .2vh 0
        }
  
        .last {
          margin: 0;
        }
      }

      .button {
        width: 15vw;
        height: 2.7vw;
        background-color: #4578FB;
        border: none;
        border-radius: 15px;
        color: #FFFFFF;
        font-size: 1.1vw;
        font-weight: 500;
        padding: 0;
        cursor: pointer;
        position: absolute;
        bottom: 8vh;

        &:hover {
          opacity: .7;
        }
      }
    }
  }
}
