/* Web */
@media only screen and (min-width: 768px) {
  .response-container {
    --response-margin: 0.7vw;
    --response-height: 25vh;
    --response-columns: 5;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: calc((96vw - var(--response-margin) * 2 * var(--response-columns)) / var(--response-columns));
    height: var(--response-height);
    margin: 0 var(--response-margin) var(--response-margin) 0;

    .info-icon {
      color: #B7B7B7;
      position: absolute;
      top: 1.5vh;
      right: 1vw;
      font-size: 1vw;
      cursor: pointer;
      z-index: 5;
    }

    .response {
      position: relative;
      height: var(--response-height);
      width: 100%;
      border: 1px solid rgba(69, 120, 251, .17);
      border-radius: 20px;
      box-sizing: border-box;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, .1);
      margin: 0;
      padding: 1vh 0 0 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      z-index: 1;

      .name {
        font-size: 1vw;
        font-weight: 500;
        color: #4578FB;
        margin: 1.5vh 0;
        cursor: pointer;
      }

      .partner-image {
        width: 7vw;
        cursor: pointer;
      }

      .products-container {
        max-width: 90%;
        height: 10vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-start;
        overflow-x: auto;
        -ms-overflow-style: none; /* for Internet Explorer, Edge */
        scrollbar-width: none; /* for Firefox */

        &::-webkit-scrollbar {
          display: none;
        }

        .product {
          border: 1px solid rgba(69, 120, 251, 0.1);
          box-sizing: border-box;
          border-radius: 10px;
          min-width: 5vw;
          min-height: 5vw;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0 .5vw 0 0;

          .product-image {
            width: 4vw;
            cursor: pointer;
          }
        }
      }

      .number-found {
        font-size: .6vw;
        font-weight: 300;
        color: #7E7E7E;
        margin: 1vh 0;
      }

      .see-products {
        font-size: .7vw;
        font-weight: 300;
        color: #4578FB;
        margin: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      .icon {
        color: #4578FB;
        font-size: 1.2vw;
      }
    }
  }
}