/* Web */
@media only screen and (min-width: 768px) {
  .none-left-border {
    border: none !important;
  }

  .product-container {
    border-left: 1px solid #F2F2F2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: min-content;
    position: relative;

    .none-stock {
      opacity: .2;
    }

    .info-promotion {
      position: absolute;
      top: 0;
      left: 1.8vw;
      width: 1.8vw;
    }

    .product-image {
      cursor: pointer;
      margin: 0 0 1vh 0;
      width: 16vh;
      height: 16vh;
    }

    .info-container {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      cursor: pointer;
      margin: 0 0 1vh 0;

      .name {
        text-align: center;
        width: 10vw;
        height: 5vh;
        text-overflow: ellipsis;
        letter-spacing: -0.333333px;
        color: #7E7E7E;
        font-size: .9vw;
        font-weight: 200;
        margin: 0;
        cursor: pointer;
      }

      .promo-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        text-align: center;
        margin: .2vw 0;
        cursor: pointer;

        .price-new {
          color: #4578FB;
          margin: 0;
          font-weight: 600;
          font-size: 1vw;
          cursor: pointer;
        }

        .price-old {
          color: #7E7E7E;
          opacity: .8;
          text-decoration: line-through;
          font-size: 1vw;
          margin: 0 1vw 0 0;
          cursor: pointer;
        }

      }

      .price-normal {
        color: #7E7E7E;
        font-weight: 600;
        font-size: 1vw;
        margin: .2vw 0 .2vw 0;
        cursor: pointer;
      }

      .type {
        color: #7E7E7E;
        font-size: .9vw;
        font-weight: 200;
        margin: 0;
        cursor: pointer;
        width: 100%;
      }
    }

    .product-interest {
      background: none;
      border: none;
      color: #4578FB;
      font-size: 1.2vw;
      cursor: pointer;
      height: 3.1vw;
    }

    .interest-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      height: calc(4.2vw + 2.5vh);

      .none-stock-message {
        margin: 4vh 0 0 0;
        font-size: 1vw;
        font-weight: 500;
        color: #4578FB;
      }

      .add-remove-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: .7vw 0 0 0;
        height: 2.4vw;
        width: 5.8vw;

        .action {
          background: none;
          border: none;
          color: #4578FB;
          font-size: 1.5vw;
          font-weight: 200;
          cursor: pointer;
        }

        .quantity {
          border: 1px solid #E5E5E5;
          box-sizing: border-box;
          box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.4vw;
          height: 2.4vw;
          margin: 0 .5vw;
          color: #4578FB;
          font-size: 1.1vw;
          text-align: center;

          .number {
            color: #4578FB;
            font-size: 1.1vw;
            margin: 0;
          }
        }
      }

      .none {
        opacity: .2 !important;
      }

      .add {
        width: 5.8vw;
        height: 1.8vw;
        background-color: #4578FB;
        color: #FFFFFF;
        border: none;
        box-sizing: border-box;
        border-radius: 30px;
        margin: 1.5vh 0 0 0;
        font-size: .8vw;
        font-weight: 300;
        cursor: pointer;
      }

      .remove {
        width: 5.8vw;
        height: 1.8vw;
        background-color: #FF2828;
        border: none;
        color: #FFFFFF;
        box-sizing: border-box;
        border-radius: 30px;
        margin: 1.5vh 0 0 0;
        font-size: .8vw;
        font-weight: 300;
        cursor: pointer;
      }

      .in-cart {
        width: 5.8vw;
        height: 1.8vw;
        background-color: #FFFFFF;
        color: #4578FB;
        border: 1px #4578FB solid;
        box-sizing: border-box;
        border-radius: 30px;
        margin: 1.5vh 0 0 0;
        font-size: .8vw;
        font-weight: 300;
      }
    }
  }

  .none-border {
    border: none;
  }

  .product-cart-container {
    height: 15vh;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    position: relative;
    border-top: .15vw solid #F2F2F2;
    cursor: pointer;

    .info-promotion {
      position: absolute;
      top: 1.5vh;
      left: .3vw;
      width: 1.6vw;
    }

    .img {
      width: 5.5vw;
      height: 5.5vw;
      cursor: pointer;
    }

    .info {
      height: 7vh;
      width: 10vw;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: space-between;
      position: relative;
      margin: 0 2.6vw 0 .8vw;
      cursor: pointer;

      .brand {
        margin: 0;
        color: #7E7E7E;
        font-size: .9vw;
        font-weight: 300;
        cursor: pointer;
      }

      .name {
        margin: 0;
        color: #7E7E7E;
        font-size: .9vw;
        font-weight: 300;
        width: 100%;
        cursor: pointer;
      }
    }

    .long-info {
      height: 9vh;
    }

    .info-total {
      height: 7vh;
      width: 4.7vw;
      padding: 0 1vw 0 0;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;
      position: relative;

      .brand {
        margin: 0;
        color: #7E7E7E;
        font-size: .9vw;
        font-weight: 700;
      }

      .name {
        margin: 0;
        color: #7E7E7E;
        font-size: .9vw;
        font-weight: 300;
      }
    }

    .product-quantity {
      height: 15vh;
      width: 5.7vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin: 0 3vw 0 0;
  
      .add-remove-container {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
  
        .action {
          background: none;
          border: none;
          color: #4578FB;
          font-size: 1.5vw;
          font-weight: 200;
          cursor: pointer;
        }
  
        .quantity {
          border: 1px solid #E5E5E5;
          box-sizing: border-box;
          box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
          border-radius: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2.4vw;
          height: 2.4vw;
          margin: 0 .5vw;
          color: #4578FB;
          font-size: 1.1vw;
          text-align: center;
  
          .number {
            color: #4578FB;
            font-size: 1.1vw;
            margin: 0;
          }
        }
      }
  
      .add {
        width: 5.8vw;
        height: 1.8vw;
        background-color: #4578FB;
        color: #FFFFFF;
        border: none;
        box-sizing: border-box;
        border-radius: 30px;
        margin: .5vh 0 0 0;
        font-size: .8vw;
        font-weight: 300;
        cursor: pointer;
      }
  
      .remove {
        width: 5.8vw;
        height: 1.8vw;
        background-color: #FF2828;
        border: none;
        color: #FFFFFF;
        box-sizing: border-box;
        border-radius: 30px;
        margin: .5vh 0 0 0;
        font-size: .8vw;
        font-weight: 300;
        cursor: pointer;
      }
  
      .in-cart {
        width: 5.8vw;
        height: 1.8vw;
        background-color: #FFFFFF;
        color: #4578FB;
        border: 1px #4578FB solid;
        box-sizing: border-box;
        border-radius: 30px;
        margin: .5vh 0 0 0;
        font-size: .8vw;
        font-weight: 300;
      }
    }
  }

  .modal {
    display: block;
    position: fixed;
    z-index: 25;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.6);

    .modal-content {
      --modal-width: 35vw;
      --modal-height: 70vh;
      --modal-padding-top: 3vh;
      --modal-padding-bottom: 3vh;
      background-color: #FFFFFF;
      margin: calc((100vh - var(--modal-height) - var(--modal-padding-top) - var(--modal-padding-bottom)) / 2) auto;
      width: var(--modal-width);
      height: var(--modal-height);
      padding: var(--modal-padding-top) 0 var(--modal-padding-bottom) 0;
      overflow-y: scroll;
      border-radius: 2vw;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;

      &::-webkit-scrollbar {
        display: none;
      }

      .modal-close {
        color: #4578FB;
        position: absolute;
        right: 2vw;
        top: 3vh;
        font-size: 2vw;
        z-index: 5;
        cursor: pointer;
    
        &:hover, &:focus, &:active {
          color: rgba(69, 119, 251, 0.5);
          text-decoration: none;
          cursor: pointer;
        }
      }

      .modal-main {
        position: relative;
        z-index: 3;
        height: calc(100% - 2vh);
        padding: 2vh 0 0 0;
        width: calc(100% - 6vw);
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;

        .img {
          width: 21vh;
          height: 21vh;
          margin: 0 0 1.5vh 0;
        }

        .modal-brand {
          width: 100%;
          text-align: center;
          color: #4578FB;
          font-weight: 500;
          font-size: 1.1vw;
          margin: 0;
        }

        .modal-name {
          width: 100%;
          text-align: center;
          color: #4578FB;
          font-weight: 300;
          font-size: 1.1vw;
          margin: 0 0 .9vh 0;
        }

        .modal-promotions {
          width: 100%;
          text-align: center;
          color: #4578FB;
          font-weight: 500;
          font-size: 1.1vw;
          margin: 0 0 1vh 0;
        }

        .specific-promotion {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          position: relative;
          margin: 4vh 0 0 0;

          .short {
            background-color: #4578FB;
            color: #FFFFFF;
            display: flex;
            align-items: center;
            justify-content: center;
            width: fit-content;
            padding: .2vw .8vw;
            border-radius: .7vw;
            font-size: 1vw;
            font-weight: 300;
          }

          .long {
            color: #4578FB;
            width: 80%;
            font-size: .8vw;
            font-weight: 300;
            margin: 1vh 0 0 0;
          }

          .details {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin: 2vh 0 0 0;
            width: 100%;
            position: relative;
            max-height: calc(4 * (3px + 6.7vh));
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-track {
              background: #F1F1F150;
              border-radius: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background: rgba(69, 120, 251, .15);
              border-radius: 5px;
            }

            .detail-container {
              margin: 0 0 1vh 0;
              border: 2px solid #4578FB;
              border-radius: 20px;
              color: #4578FB;
              font-size: .8vw;
              font-weight: 300;
              width: 50%;
              padding: 1vh 3vw;

              p {
                display: inline;
                margin: 0;
              }

              .info {
                font-weight: bold;
              }
            }
          }
        }

        .modal-promotion {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: relative;
          cursor: pointer;
          margin: 0 0 1vh 0;

          p {
            margin: 0;
            color: #4578FB;
            font-weight: 600;
            cursor: pointer;
          }

          .short-promotion {
            max-width: 90%;
            margin: 0 1vw 0 0;

            div {
              margin: .3vh;
              border: 1px solid #4578FB;
              border-radius: .7vw;
              color: #4578FB;
              padding: .2vw .8vw;
              width: fit-content;
              display: inline-flex;
              font-size: 1vw;
              font-weight: 300;
              cursor: pointer;
            }
          }

          .info-icon {
            color: #4578FB;
            cursor: pointer;
            font-size: 1.3vw;
            margin: 0 1vw 0 0;
          }
        }

        .product-info {
          width: calc(100% - 1vw);
          max-height: 25vh;
          display: flex;
          flex-direction: column;
          position: relative;
          overflow-y: auto;
          padding: 0 1vw 0 0;
          margin: 0;

          &::-webkit-scrollbar {
            width: .3vw;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(69, 120, 251, 0.5);
          }

          &::-webkit-scrollbar-track {
            margin: 0;
            background: #E5E5E5;
          }

          .flex-div {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin: 0 0 1vh 0;
  
            .blue {
              color: #4578FB;
              font-weight: 300;
              font-size: 1vw;
              margin: 0;
            }

            .red {
              color: #FF2828;
              font-weight: 300;
              font-size: 1vw;
              margin: 0;
            }
  
            .promo-container {
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              text-align: center;
            }
  
            .price-old {
              color: #7E7E7E;
              opacity: .8;
              text-decoration: line-through;
              font-size: .8vw;
              margin: 0 .5vw 0 0;
            }
  
            .price-old-gray {
              color: #7E7E7E;
              opacity: .6;
              text-decoration: line-through;
              font-size: .8vw;
              font-weight: 300;
              font-style: italic;
              margin: 0 .5vw 0 0;
            }
  
            .gray {
              color: #a8a8a8;
              font-weight: 300;
              font-size: .8vw;
              font-style: italic;
              margin: 0;
            }
          }

          .description-container {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;
            margin: 0 0 1vh 0;
            position: relative;

            .label {
              color: #a8a8a8;
              font-weight: 300;
              font-size: .8vw;
              font-style: italic;
              margin: 0;
              text-align: left;
              width: 100%;
              margin: 0 0 .5vh 0;
            }

            .text {
              color: #a8a8a8;
              font-weight: 300;
              font-size: .8vw;
              font-style: italic;
              margin: 0;
              text-align: left;
              width: 100%;
              margin: 0;
            }
          }
        }

        .modal-bottom {
          width: 100%;
          height: min-content;
          position: absolute;
          bottom: 2vh;
          left: 0;

          .modal-total-container {
            width: 100%;
            height: 6vh;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-top: .5px solid #DADADA;
            border-bottom: .5px solid #DADADA;
            margin: 2vh 0 3vh 0;
  
            .label {
              color: #4578FB;
              font-weight: 500;
              font-size: 1.1vw;
              margin: 0;
              text-align: left;
            }
  
            .total {
              color: #4578FB;
              font-weight: 500;
              font-size: 1.1vw;
              margin: 0;
              text-align: right;
            }
          }
  
          .modal-user-quantity {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            margin: 0;
  
            .modal-quantity {
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
  
              .add-remove-container {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin: 0;
                height: 2.7vw;
  
                .action {
                  background: none;
                  border: none;
                  color: #4578FB;
                  font-size: 1.5vw;
                  font-weight: 200;
                  cursor: pointer;
                }
    
                .none {
                  opacity: .2;
                }
    
                .quantity {
                  border: 1px solid #E5E5E5;
                  box-sizing: border-box;
                  box-shadow: 1px 3px 10px rgba(0, 0, 0, 0.25);
                  border-radius: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 2.7vw;
                  height: 2.7vw;
                  margin: 0 .5vw;
                  color: #4578FB;
                  font-size: 1.1vw;
                  text-align: center;
    
                  .number {
                    color: #4578FB;
                    font-size: 1.1vw;
                    margin: 0;
                  }
                }
              }
            }
  
            .modal-button-quantity {
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
  
              button {
                width: 9vw;
                height: 2.7vw;
                background-color: #4578FB;
                border: none;
                border-radius: 10px;
                color: #FFFFFF;
                font-size: 1vw;
                font-weight: 300;
                padding: 0;
                cursor: pointer;
              }
  
              .in-cart {
                width: 9vw;
                height: 2.7vw;
                background-color: #FFFFFF;
                border: 1px solid #4578FB;
                border-radius: 10px;
                color: #4578FB;
                font-size: 1vw;
                font-weight: 300;
                padding: 0;
                cursor: default;
              }
  
              .button-remove {
                width: 9vw;
                height: 2.7vw;
                background-color: #fb4545 !important;
                border: none;
                border-radius: 10px;
                color: #FFFFFF;
                font-size: 1vw;
                font-weight: 300;
                cursor: pointer;
              }
  
              .none {
                opacity: .5;
              }
            }
          }
        }
      }

      .back-container {
        position: absolute;
        bottom: 5vh;
        left: 12vw;
        width: 6vw;
        z-index: 30;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin: 5vh 0 0 0;
        cursor: pointer;

        .icon {
          color: #4577FB;
          font-size: 3.5vh;
          transform: rotate(180deg);
          cursor: pointer;
        }

        p {
          font-size: 1vw;
          font-weight: 300;
          color: #4578FB;
          margin: 0;
          cursor: pointer;
        }
      }
    }
  }
}
