/* Web */
@media only screen and (min-width: 768px) {
  .sub-category-container {
    position: relative;
    width: 96vw;
    background: #FFFFFF;
    border: 1px solid rgba(69, 120, 251, 0.17);
    box-sizing: border-box;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
    border-radius: 30px;
    overflow: hidden;
    margin: 0 0 1.7vh 0;
    padding: 3.3vh 0 3.3vh 0;

    .name-sub-category {
      position: relative;
      width: calc(100% - 4vw);
      height: 2.8vh;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0 2vw 1.2vh 1.5vw;
      cursor: pointer;

      p {
        margin: 0;
        color: #4578FB;
        font-size: 1.2vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .icon {
          font-size: 2vw;
          transform: rotate(90deg);
        }

        .icon2 {
          font-size: 2vw;
        }
      }
    }

    .name-sub-category-grid {
      position: relative;
      width: calc(100% - 4vw);
      height: 2.8vh;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 0 2vw 0 1.5vw;
      cursor: pointer;

      p {
        margin: 0;
        color: #4578FB;
        font-size: 1.2vw;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        .icon2 {
          font-size: 2vw;
        }
      }
    }

    .products-grid {
      width: 91vw;
      overflow: hidden;
      display: grid;
      grid-template-columns: repeat(7, 13vw);
      grid-row-gap: 6vh;
      padding: 0 2.5vw 2vh 2.5vw;
    }
  }
}
