/* Web */
@media only screen and (min-width: 768px) {
  .signup-container {
    height: 100vh;
    width: 100vw;
    background: linear-gradient(105.89deg, #4578FB 0%, #4578FB 0%, #28C3FF 76.56%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    *:focus {
      outline: none;
    }

    .back-link {
      position: absolute;
      left: 3vw;
      top: 6vh;
      background: none;
      border: none;
      cursor: pointer;

      .icon {
        color: #FFFFFF;
        font-size: 8vh;
        transform: rotate(180deg);
        cursor: pointer;
      }
    }

    .landing-link {
      position: relative;
      cursor: pointer;
      margin-bottom: 3vw;

      .logo {
        width: 24.7vw;
      }
    }

    .data-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      height: 45vh;

      .user-data {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        width: calc(50vw - .15vw - 3.6vw);
        border-right: dashed .15vw;
        border-right-color: rgba(255, 255, 255, 0.5);
        height: 45vh;
        padding-left: 3.6vw;
      }

      .store-data {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: left;
        width: 50vw;
        height: 45vh;
      }

      .signup-label {
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 5vh;
        width: calc(50vw - (50vw - 34.05vw) / 2);
        padding-left: calc((50vw - 34.05vw) / 2 - 2vw);

        .icon {
          color: #FFFFFF;
          font-size: 4.5vh;
        }

        .message {
          font-size: 1.5vw;
          font-weight: 500;
          color: #FFFFFF;
        }
      }

      .inputs-container {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: left;
        width: calc(50vw - (50vw - 34.05vw) / 2);
        padding-left: calc((50vw - 34.05vw) / 2 - 2vw);
        height: 45vh;

        .input-container {
          height: calc(6vh + 1.6vw);
          width: calc(32.05vw + .3vw + 2px + 2vw);
          display: flex;
          flex-direction: column;
          align-items: left;
          position: relative;

          .valid {
            border: 2px solid #09FFE1;
          }

          .signup-input2 {
            width: 29.05vw;
            height: 5.5vh;
            background-color: transparent;
            border: 2px solid #FFFFFF;
            border-radius: 1.2vw;
            font-size: 1.3vw;
            color: #FFFFFF;
            padding: 0 3vw .5vh 2vw;
            position: relative;
            margin: 0;
        
            // Background color
            &:-webkit-autofill {
              transition: background-color 5000s ease-in-out 0s;
            }
              
            // Font styles
            &:-webkit-autofill::first-line {
              font-family: 'museo-sans';
              font-size: 1.3vw;
              color: #FFFFFF;
            }

            &::placeholder {
              color: rgba(255, 255, 255, 0.7);
              font-weight: 300;
              font-style: italic;
              font-size: 1.1vw;
            }
          }

          .not-valid-email {
            margin: .1vw 0 0 2vw;
            color: #FFFFFF;
            font-style: italic;
            font-weight: 200;
            text-align: left;
            font-size: .9vw;
          }

          .icon-eye {
            color: #FFFFFF;
            height: calc(5.5vh + .3vw - 2vh);
            position: absolute;
            top: 0;
            right: 0;
            padding: 1.8% 3.5% 0 0;
            font-size: 1.8vw;
            cursor: pointer;
          }
        }

        .not-valid {
          opacity: .7;
          border-color: rgba(255, 255, 255, .7);
        }

        .signup-input {
          width: 30.05vw;
          height: 5.5vh;
          background-color: transparent;
          border: 2px solid #FFFFFF;
          border-radius: 1.2vw;
          font-size: 1.3vw;
          color: #FFFFFF;
          padding: 0 2vw .5vh 2vw;
          margin-bottom: 1.3vw;
          position: relative;
      
          // Background color
          &:-webkit-autofill {
            transition: background-color 5000s ease-in-out 0s;
          }
            
          // Font styles
          &:-webkit-autofill::first-line {
            font-family: 'museo-sans';
            font-size: 1.3vw;
            color: #FFFFFF;
          }

          &::placeholder {
            color: rgba(255, 255, 255, 0.7);
            font-weight: 300;
            font-style: italic;
            font-size: 1.1vw;
          }
        }

        .select-container {
          width: 34.05vw;
          margin-bottom: 1.3vw;
          box-sizing: content-box;
        }

        .select-order__control {
          width: 30.05vw;
          height: 5.5vh;
          background-color: transparent;
          border: .15vw solid #FFFFFF;
          border-radius: 1.2vw;
          font-size: 1.3vw;
          color: #FFFFFF;
          padding: 0 2vw .5vh 2vw;
          position: relative;
          box-sizing: content-box;
          cursor: text;
          pointer-events: all;
          min-height: 0;

          &:hover {
            border-color: #FFFFFF;
          }
        }

        .select-order__control--is-focused {
          border-color: #FFFFFF;
          box-shadow: 0 0 0 0px transparent;

          &:hover {
            border-color: #FFFFFF;
          }
        }

        .select-order__control--menu-is-open {
          border-color: #FFFFFF;
        }

        .select-order__value-container {
          padding-left: 0;
          padding-right: 0;
        }
      
        .select-order__single-value {
          margin-left: 0;
          font-size: 1.3vw;
          color: #FFFFFF;
        }
      
        .select-order__placeholder {
          color: rgba(255, 255, 255, 0.7);
          font-weight: 300;
          font-style: italic;
          font-size: 1.1vw;
        }

        .select-order__menu {
          width: 34.35vw;
          border-radius: 1vw;
          margin-top: 0vw;
          padding-top: 1vw;
          padding-bottom: 1vw;
          box-sizing: content-box;
        }
      
        .select-order__option {
          color: #4578FB;
          font-weight: normal;
          font-style: italic;
          font-size: 1.1vw;
          display: flex;
          align-items: flex-start;
          padding-left: 2.5vw;
        }
      
        .select-order__option--is-selected {
          color: #FFFFFF;
        }
      
        .select-order__menu-notice--no-options {
          color: #7b7b7b;
          font-weight: normal;
          font-style: italic;
          font-size: 1.1vw;
        }

        .select__control {
          width: 30.05vw;
          height: 5.5vh;
          background-color: transparent;
          border: 2px solid #FFFFFF;
          border-radius: 1.2vw;
          font-size: 1.3vw;
          color: #FFFFFF;
          padding: 0 2vw .5vh 2vw;
          position: relative;
          box-sizing: content-box;
          cursor: text;
          pointer-events: all;
          min-height: 0;

          &:hover {
            border-color: #FFFFFF;
          }
        }

        .select__control--is-focused {
          border-color: #FFFFFF;
          box-shadow: 0 0 0 0px transparent;

          &:hover {
            border-color: #FFFFFF;
          }
        }

        .select__control--menu-is-open {
          border-color: #FFFFFF;
        }

        .select__value-container {
          padding-left: 0;
          padding-right: 0;
        }
      
        .select__single-value {
          display: none;
          margin-left: 0;
          font-size: 1.3vw;
          color: #FFFFFF;
        }
      
        .select__input {
          box-sizing: content-box;
          font-size: 1.3vw;
          color: #FFFFFF;
          align-self: start;
          width: 100%;
        
          input {
            font-style: italic;
            opacity: 1 !important;
            width: 100% !important;
          }
        }
      
        .select__placeholder {
          color: rgba(255, 255, 255, 0.7);
          font-weight: 300;
          font-style: italic;
          font-size: 1.1vw;
        }

        .select__menu {
          width: 34.35vw;
          border-radius: 1vw;
          margin-top: 0vw;
          padding-top: 1vw;
          padding-bottom: 1vw;
          box-sizing: content-box;
        }
      
        .css-b8ldur-Input {
          margin: 0;
          padding: 0;
          font-size: 1.1vw;
          height: fit-content;
          width: 100%;
        }
      
        .select__option {
          color: #4578FB;
          font-weight: normal;
          font-style: italic;
          font-size: 1.1vw;
          display: flex;
          align-items: flex-start;
          padding-left: 2.5vw;
        }
      
        .select__option--is-selected {
          color: #FFFFFF;
        }
      
        .select__menu-notice--no-options {
          color: #7b7b7b;
          font-weight: normal;
          font-style: italic;
          font-size: 1.1vw;
        }

        .select-disabled__control {
          width: 30.05vw;
          height: 5.5vh;
          background-color: transparent;
          border: 2px solid #FFFFFF;
          border-radius: 1.2vw;
          font-size: 1.3vw;
          color: #FFFFFF;
          padding: 0 2vw .5vh 2vw;
          position: relative;
          box-sizing: content-box;
          min-height: 0;
          cursor: text;
          pointer-events: all;
        }

        .select-disabled__control--is-disabled {
          border-color: rgba(255, 255, 255, .7);
          opacity: .7;
          cursor: text;
          pointer-events: all;
      
          &:hover {
            border-color: rgba(255, 255, 255, .7);
          }
        }

        .select-disabled__value-container {
          padding-left: 0;
        }

        .select-disabled__placeholder {
          color: rgba(255, 255, 255, 0.7);
          font-weight: 300;
          font-style: italic;
          font-size: 1.1vw;
          margin-left: 0;
        }
      }
    }

    .confirm-button {
      width: 34.45vw;
      height: calc(6vh + .3vw);
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.5);
      font-size: 1.3vw;
      font-weight: 500;
      color: #4578FB;
      border-radius: 1.2vw;
      border: none;
      margin: 4vh 0 0 0;
      position: relative;
      cursor: pointer;
    }

    .confirm-button-white {
      width: 34.45vw;
      height: calc(6vh + .3vw);
      justify-content: center;
      background-color: rgba(255, 255, 255, 1);
      font-size: 1.3vw;
      font-weight: 500;
      color: #4578FB;
      border-radius: 1.2vw;
      border: none;
      margin: 4vh 0 0 0;
      position: relative;
      cursor: pointer;
    }

    .accept-label {
      position: relative;
      font-size: 1.1vw;
      margin: 1.3vw 0 0 0;
      color: #FFFFFF;
    }
  
    .accept-link {
      position: relative;
      margin: 0;
      font-size: 1.1vw;
      color: #FFFFFF;
      display: inline;
    }
  
    .accept-label2 {
      position: relative;
      font-size: 1.1vw;
      margin: 0;
      color: #FFFFFF;
      display: inline;
      text-decoration: none;
    }
  }

  .modal-signup {
    display: block;
    position: fixed;
    z-index: 25;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding: 0;
    overflow: auto;
    background-color: rgba(0,0,0,0.6);

    .modal-content {
      background-color: #FFFFFF;
      margin: 32.5vh 35vw;
      width: 30vw;
      height: 35vh;
      padding: 0;
      overflow-y: scroll;
      border-radius: 2vw;
      position: relative;

      &::-webkit-scrollbar {
        display: none;
      }

      .modal-main {
        position: relative;
        z-index: 3;
        margin: 0 6vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .modal-title {
          margin: 0 0 2vh 0;
          font-size: 1.3vw;
          font-weight: 500;
          width: 15vw;
          color: #4577FB;
        }

        .modal-description {
          margin: 0 0 2vh 0;
          font-size: 1.3vw;
          font-weight: 300;
          width: 20vw;
          color: #4577FB;
        }

        .modal-button {
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
          text-decoration: none;
          background: #4577FB;
          width: 12vw;
          height: 5vh;
          margin: 2vh 0 0 0;
          border-radius: 1vw;
          border-color: transparent;

          &:hover,
          &:focus,
          &:active {
            cursor: pointer;
            background: rgba(69, 119, 251, 0.8);
          }

          p {
            color: #FFFFFF;
            font-size: 1.2vw;
            font-weight: 300;
            margin: 0;
            cursor: pointer;
          }
        }
      }
    }
  }
    
  // .modal-signup {
  //   display: block;
  //   position: fixed;
  //   z-index: 25;
  //   padding-top: 5vh;
  //   left: 0;
  //   top: 0;
  //   width: 100%;
  //   height: 100%;
  //   overflow: auto;
  //   background-color: rgba(0,0,0,0.6);
  // }

  // .modal-signup .modal-content {
  //   background-color: #FFFFFF;
  //   margin: auto;
  //   padding: 3vw 0;
  //   width: 40vw;
  //   height: 80vh;
  //   overflow-y: scroll;
  //   border-radius: 2vw;
  // }

  // .modal-signup .modal-content::-webkit-scrollbar {
  //   display: none;
  // }

  // .modal-signup .modal-content .modal-main {
  //   position: relative;
  //   z-index: 3;
  //   margin: 0 6vw;
  //   height: 100%;
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: center;
  //   align-items: center;
  //   text-align: center;
  // }

  // .modal-signup .modal-content .icon {
  //   font-size: 8vw;
  //   color: #4578FB;
  //   margin: 0;
  // }

  // .modal-signup .modal-content .modal-title {
  //   margin: 0 0 0 0;
  //   font-size: 1.3vw;
  //   font-weight: 600;
  //   color: #4578FB;
  //   width: 55%;
  // }

  // .modal-signup .modal-content .modal-description {
  //   margin: 3vh 0 10vh 0;
  //   font-size: 1.1vw;
  //   font-weight: 300;
  //   color: #4578FB;
  //   width: 60%;
  // }

  // .modal-signup .modal-content .industry {
  //   box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.25);
  //   border-radius: 40px;
  //   width: 55%;
  //   height: 6vh;
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   color: #4578FB;
  //   font-size: 1.2vw;
  //   font-weight: 500;
  //   margin: 0 0 3vh 0;
  //   cursor: pointer;
  // }
  // .modal-signup .modal-content .none-interest {
  //   opacity: .4;
  // }

  // .modal-signup .modal-content .modal-button {
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   z-index: 3;
  //   text-decoration: none;
  //   background: #4578FB;
  //   width: 60%;
  //   height: 3vw;
  //   border-radius: 1vw;
  //   border-color: transparent;
  //   border-style: none;
  //   margin-top: 7vh;
  //   margin-bottom: 2vw;
  //   cursor: pointer;
  // }

  // .modal-signup .modal-content .modal-button p {
  //   color: #FFFFFF;
  //   font-size: 1.2vw;
  //   font-weight: 700;
  //   margin: 0;
  //   cursor: pointer;
  // }
}
