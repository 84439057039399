/* Web */
@media only screen and (min-width: 768px) {
  .modal-store {
    display: flex;
    position: fixed;
    z-index: 25;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.6);

    .content {
      background-color: #FFFFFF;
      margin: auto;
      padding: 5vh 0;
      width: 36vw;
      height: 75vh;
      border-radius: 2vw;

      &:-webkit-scrollbar {
        display: none;
      }

      #modal-close {
        position: absolute;
        z-index: 5;
        right: 34vw;
        top: 11vh;
        color: #4577FB;
        font-size: 2vw;
        padding: 0 1vw;

        &:hover,
        &:focus,
        &:active {
          color: rgba(69, 119, 251, 0.5);
          text-decoration: none;
          cursor: pointer;
        }
      }
      
      .main {
        position: relative;
        z-index: 3;
        margin: 0 4vw 0 4vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .title {
          margin: 1.8vw 0 0 0;
          font-size: 1.2vw;
          font-weight: 700;
          color: #4577FB;
          justify-self: flex-start;
        }

        .form-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 1.5vh;
          height: 55vh;
          overflow-y: auto;
          margin-bottom: 2vh;
          padding: 0 1vw 2vh 1vw;

          &::-webkit-scrollbar {
            width: .3vw;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(69, 120, 251, 0.5);
          }

          &::-webkit-scrollbar-track {
            margin: 7vh 0;
            background: #E5E5E5;
          }

          .section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title-section {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 4vh 0 0 2vh;

              .icon {
                color: #4577FB;
                font-size: 2.5vh;
              }

              .text {
                font-size: 2vh;
                font-weight: 500;
                margin: 0;
                color: #4577FB;
              }
            }

            .label {
              color: #b7b7b7;
              font-weight: normal;
              font-style: italic;
              font-size: 1.8vh;
              font-weight: 300;
              margin: 1vh 0 .3vh calc(2.5vh + 1vw);
            }

            .input-container {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              border: 1px solid #b7b7b7;
              border-radius: 2vh;
              width: 25vw;
              margin-left: 2.5vh;
              height: 6vh;
              position: relative;

              .field {
                background-color: transparent;
                border-color: transparent;
                color: #4578FB;
                font-size: 1.9vh;
                font-weight: 400;
                font-style: italic;
                margin: 0 1vw 0 1vw;
                width: 23vw;
                opacity: .7;
              }

              .field-off {
                background-color: transparent;
                border-color: transparent;
                color: #7e7e7e;
                font-size: 1.9vh;
                font-weight: 400;
                font-style: italic;
                margin: 0 1vw 0 1vw;
                width: 23vw;
              }

              .phone-field {
                padding: 1vh 1vw 1vh 3.05vw;
                width: calc(23vw - 2.05vw);
                letter-spacing: .3px;
              }

              .phone-label-container {
                position: absolute;
                bottom: 0;
                left: 1.5vw;
                height: 6vh;
                display: flex;
                align-items: center;
  
                .phone-label {
                  margin: 0;
                  font-size: 1.9vh;
                  font-weight: 400;
                  font-style: italic;
                  color: #4578FB;
                  opacity: .5;
                  padding: 0 .3vw 0 0;
                  border-right: .5px #4578FB solid;
                }
              }

              ul {
                position: absolute;
                z-index: 50;
                top: calc(5.5vh + 5px);
                width: 100%;
                height: fit-content;
                list-style: none;
                border: 1px solid #DADADA;
                background-color: #FFFFFF;
                border-radius: 10px;
                padding: .5vh 0;
                margin: 0;
            
                .none-border {
                  border: none;
                }
            
                li {
                  position: relative;
                  z-index: 60;
                  width: calc(100% - 2vw);
                  background-color: #FFFFFF;
                  padding: 1vh 1vw;
                  color: #4578FB;
                  opacity: .7;
                  border-top: 1.5px solid rgba(69, 120, 251, .5);
                  cursor: pointer;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
            
                  &:hover {
                    background-color: #4579fb40;
                  }
  
                  p {
                    margin: 0 0 0 .5vw;
                    font-size: 1vw;
                    font-weight: 300;
                  }
  
                  .icon {
                    font-size: 1.3vw;
                  }
                }
              }

              .help-icon {
                color: #4578FB;
                font-size: 1.3vw;
                position: absolute;
                bottom: 1.7vh;
                right: .8vw;
                cursor: pointer;
              }

            }

            .not-valid {
              border: 1px solid #ff7373;
            }
            
            .select-container {
              width: 25vw;
              margin-left: 2.5vh;
              height: 6vh;
            }

            .select__control {
              border: 1px solid #b7b7b7;
              border-radius: 2vh;
              background-color: transparent;
              height: 6vh;
              width: 25vw;
              padding: 0 1vw 0 1vw;
            }

            .select__single-value {
              display: none;
              color: #4578FB;
              font-size: 1.9vh;
              font-weight: 400;
              font-style: italic;
              opacity: .7;
            }

            .select__input {
              color: #4578FB;
              font-size: 1.9vh;
              font-weight: 400;
              font-style: italic;
              align-self: start;
              width: 100%;
              opacity: .7;

              input {
                font-style: italic;
                opacity: 1 !important;
                width: 100% !important;
                background-color: #FFFFFF !important;
              }
            }

            .css-b8ldur-Input {
              width: 100%;
            }

            .select__placeholder {
              color: #4578FB;
              font-size: 1.9vh;
              font-weight: 400;
              font-style: italic;
              opacity: .7;
            }

            .select__option {
              color: #4578FB;
              font-size: 1.9vh;
              font-weight: 400;
              font-style: italic;
              display: flex;
              padding-left: 2vw;
              align-content: flex-start;
              opacity: .7;
            }

            .select__option--is-selected {
              background-color: #4578FB;
              color: #FFFFFF;
              opacity: .7;
            }

            .select-off-container {
              width: 25vw;
              margin-left: 1.5vw;
              height: 6vh;
            }

            .select-off__control {
              border: 1px solid #b7b7b7;
              border-radius: 2vh;
              background-color: transparent;
              height: 6vh;
              width: 25vw;
              padding: 0 1vw 0 1vw;
            }

            .select-off__single-value {
              color: #7e7e7e;
              font-size: 1.9vh;
              font-weight: 400;
              font-style: italic;
            }
          }

          .toggle {
            margin-left: 1.5vw;
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-top: 1vh;

            .icon {
              color: #4578FB;
              cursor: pointer;
              font-size: 4vh;
            }

            .text {
              color: #4578FB;
              font-size: 2vh;
              margin-left: 0.7vw;
            }

            .off {
              color: #b7b7b7;
            }
          }
        }

        .menu {
          padding-bottom: calc(2vh + 250px);
        }

        .save-button {
          justify-self: flex-end;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #4577FB;
          border-radius: 1.5vh;
          border-color: transparent;
          height: 5vh;
          width: 17vw;
          cursor: pointer;

          &:hover,
          &:focus,
          &:active {
            cursor: pointer;
            background: rgba(69, 119, 251, 0.8);
          }

          .text {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 2.1vh;
            cursor: pointer;
          }
        }        
      }
    }

    .ask-address-modal {
      position: absolute;
      bottom: 0;
      left: 0;
      display: flex;
      height: 100vh;
      width: 100vw;
      background-color: rgba(0, 0, 0, .5);
      z-index: 15;
  
      .modal-content {
        position: absolute;
        bottom: 35.5vh;
        left: 35vw;
        width: 30vw;
        height: 23vh;
        background-color: white;
        border-radius: 2vw;
        z-index: 16;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding: 6vh 0;

        p {
          margin: 0 0 1vh 0;
          color: #4578FB;
          font-size: 1.3vw;
          font-weight: 300;
          width: 70%;
          text-align: center;
        }

        .update-info-p {
          margin: 3vh 0 0 0;
        }

        .buttons {
          margin: 2vh 0 0 0;
          width: 80%;
          display: flex;
          flex-direction: row;
          position: relative;
          align-items: center;
          justify-content: space-between;

          button {
            width: 45%;
            height: 5vh;
            border: none;
            border-radius: .7vw;
            color: #FFFFFF;
            background-color: #4578FB;
            font-size: 1.4vw;
            font-weight: 500;
            cursor: pointer;
          }
        }
      }
    }
  }
}
