/* Web */
@media only screen and (min-width: 768px) {
  html, body, #root {
    position: relative !important;
    overflow-x: hidden !important;
  }

  .category-view-container {
    width: 100vw;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 calc(100vw - 100%) 4vh 0;

    .info-bar {
      width: calc(100% - 2.5vw);
      height: 15vh;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 0 0 2.5vw;

      .info1 {
        display: flex;
        flex-direction: row;

        .icon {
          color: #4577FB;
          font-size: 3.5vh;
        }

        .label-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }

        .p-label {
          margin: 0;
          color: #4578FB;
          font-weight: 600;
          font-size: 1.2vw;
          width: 20vw;
          margin: 0;
        }
      }

      .info2 {
        width: 20vw;
        position: relative;
        margin: 0 3.5vw 0 0;

        .search-input {
          width: 13vw;
          height: 5vh;
          padding: 0 3vw 0 4vw;
          border: 2px solid #4578FB;
          border-radius: 3vw;
          color: #4578FB;
          font-size: 1.2vw;

          &::placeholder {
            color: #4578FB;
            opacity: .5;
            font-weight: 300;
            font-size: 1.2vw;
          }
        }

        .search-icon {
          position: absolute;
          top: 1.2vh;
          left: 1.3vw;
          font-size: 2vw;
          color: #4578FB;
          opacity: .5;
          cursor: pointer;
        }

        .close-icon {
          position: absolute;
          top: .7vh;
          right: 1.3vw;
          font-size: 2vw;
          color: #4578FB;
          opacity: .5;
          cursor: pointer;
        }
      }
    }

    .modal {
      display: block;
      position: fixed;
      z-index: 25;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      background-color: rgba(0,0,0,0.6);

      .modal-content2 {
        background-color: #FFFFFF;
        margin: 27.5vh 37.5vw;
        width: 25vw;
        height: 45vh;
        overflow-y: scroll;
        border-radius: 2vw;
        position: relative;

        &::-webkit-scrollbar {
          display: none;
        }

        .modal-main2 {
          position: relative;
          z-index: 3;
          margin: 0 6vw;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .modal-title2 {
            margin: 0 0 1vw 0;
            font-size: 1.2vw;
            font-weight: 500;
            width: 18vw;
            color: #4577FB;
          }

          .modal-message {
            margin: 0 0 1vw 0;
            font-size: .9vw;
            font-weight: 300;
            width: 18vw;
            color: #4578FB;
          }

          .icon-face {
            margin: 0 0 3vh 0;
            font-size: 4.5vw;
            color: #4578FB;
          }

          .back {
            color: #4577FB;
            font-size: 1.2vw;
            font-weight: 300;

            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
