@media only screen and (min-width: 768px) {
.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(101.46deg, #4578FB 43.44%, #28C3FF 117.29%);
    box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
    width: 100%;
    z-index: 1;
    min-height: 13vh;
    position: relative;

    .logo {
      width: 14vw;
      margin: 0 0 0 3.5vw;
    }

    .back-container {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      height: min-content;
      width: min-content;
      padding: 0;
      margin: 0 0 0 3.5vw;
      cursor: pointer;

      .icon {
        color: #FFFFFF;
        font-size: 3.5vh;
        transform: rotate(180deg)
      }

      p {
        color: #FFFFFF;
        width: 6.5vw;
        font-size: 1.2vw;
        font-weight: 400;
        margin: 0 0 0 .5vw;
        cursor: pointer;
      }
    }

    .user {
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 13vh;

      .user-info {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        height: 13vh;
        margin: 0 3vw 0 0;

        .store-name {
          color: #FFFFFF;
          margin: 0;
          font-size: 1.2vw;
        }
      }

      button {
        height: 7vh;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        border: 2px solid #FFFFFF;
        border-radius: 2vw;
        background-color: transparent;
        width: auto;
        padding: 0 1vw;
        margin: 0 3vw 0 0;
        cursor: pointer;

        p {
          margin: 0;
          color: #FFFFFF;
          font-size: 1vw;
          cursor: pointer;
        }

        .icon {
          color: #FFFFFF;
          margin: 0 1vw 0 0;
          font-size: 2.3vw;
        }
      }

      .cart-bubble {
        position: relative;
        margin: 0 3.5vw 0 0;
        display: flex;
        height: 3.3vw;
        padding-bottom: 1vw;
        align-items: flex-end;

        .cart-icon {
          color: #FFFFFF;
          margin: 0;
          font-size: 2.3vw;
          cursor: pointer;
        }

        .bubble {
          display: flex;
          color: #FFFFFF;
          background-color: #FF2828;
          position: absolute;
          // bottom: 4.5vh;
          // left: 1.5vw;
          top: .6vh;
          left: 1.4vw;
          width: 1.3vw;
          height: 1.3vw;
          border-color: #FF2828 ;
          border-radius: 50vw;
          font-size: .8vw;
          font-weight: 600;
          align-items: center;
          justify-content: center;
          cursor: default;
        }
      }
    }
  }
}
