/* Web */
@media only screen and (min-width: 768px) {
  .confirm-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .6);    
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 100;

    .confirm-container {
      width: 24vw;
      height: 19.5vh;
      background-color: #FFFFFF;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-radius: 4.5vh;
      justify-content: space-between;
      padding: 8vh 3vw 8vh 3vw;

      .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;

        p {
          color: #4578FB;
          font-size: calc(min(2.3vh, 1.25vw));
          margin: 0 0 0 0;
          font-weight: normal;
        }

        .bold {
          font-weight: bold;
        }      
      }

      .options {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 90%;

        .button {
          height: 5vh;
          width: 9vw;
          background-color: #4578FB;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          border-radius: 1.7vh;
          border-color: transparent;
          cursor: pointer;
  
          .text {
            font-weight: 500;
            font-size: calc(min(2.3vh, 1.25vw));
            color: #FFFFFF;
            font-style: normal;
            margin: 0 0 0 0;
            cursor: pointer;
          }
        }
      }
    }
  }
}
