/* Web */
@media only screen and (min-width: 768px) {
  .partners-container {
    height: 100vh;
    background: #FFFFFF;
    display: flex;
    flex-direction: column;
    align-items: center;

    .patners-content {
      width: 100%;

      .info-bar {
        width: calc(100% - 2.5vw);
        height: 15vh;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0 0 0 2.5vw;
  
        .info1 {
          display: flex;
          flex-direction: row;
  
          .icon {
            color: #4577FB;
            font-size: 3.5vh;
          }
  
          .label-container {
            display: flex;
            align-items: center;
            justify-content: center;
  
            .p-label {
              margin: .1vh 0 0 0;
              color: #4578FB;
              font-weight: 600;
              font-size: 1.2vw;
              width: 20vw;
            }
          }
        }
  
        .info2 {
          width: 20vw;
          position: relative;
          margin: 0 3.5vw 0 0;
  
          .search-input {
            width: 13vw;
            height: 5vh;
            padding: 0 3vw 0 4vw;
            border: 2px solid #4578FB;
            border-radius: 3vw;
            color: #4578FB;
            font-size: 1.2vw;
  
            &::placeholder {
              color: #4578FB;
              opacity: .5;
              font-weight: 300;
              font-size: 1.2vw;
            }
          }
  
          .search-icon {
            position: absolute;
            top: 1.2vh;
            left: 1.3vw;
            font-size: 2vw;
            color: #4578FB;
            opacity: .5;
            cursor: pointer;
          }

          .close-icon {
            position: absolute;
            top: .7vh;
            right: 1.3vw;
            font-size: 2vw;
            color: #4578FB;
            opacity: .5;
            cursor: pointer;
          }
        }
      }
  
      .partners-grid {
        --partnes-grid-width: 96vw;
        width: var(--partnes-grid-width);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto 4vh;
        overflow: hidden;
  
        .partner-container {
          --partner-margin: 0.7vw;
          --partner-height: 25vh;
          --partner-columns: 5;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          width: calc((var(--partnes-grid-width) - var(--partner-margin) * 2 * var(--partner-columns)) / var(--partner-columns));
          height: var(--partner-height);
          margin: 0 var(--partner-margin) var(--partner-margin) 0;
  
          .partner {
            position: relative;
            height: var(--partner-height);
            width: 100%;
            border: 1px solid rgba(69, 120, 251, .17);
            border-radius: 20px;
            box-sizing: border-box;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, .1);
            margin: 0;
            padding: 3.5vh 0 0 0;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            z-index: 1;
  
            .partner-image {
              width: 13vw;
            }
          }
  
          .info-container {
            position: absolute;
            bottom: 2.2vh;
            left: 0;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
  
            .info {
              position: relative;
              width: fit-content;
              padding: 0 1.5vw;
              height: 4vh;
              border: 1px solid rgba(69, 120, 251, 0.7);
              box-sizing: border-box;
              border-radius: 20px;
              z-index: 5;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
    
              .info-icon {
                color: rgba(69, 120, 251, 0.7);
                font-size: 1.2vw;
                margin: 0 .8vw 0 0;
                cursor: pointer;
              }
    
              p {
                margin: 0;
                font-weight: 300;
                font-size: .75vw;
                color: rgba(69, 120, 251, 0.7);
                cursor: pointer;
              }
  
              &:hover {
                background-color: #4579FB;
  
                .info-icon,
                p {
                  color: #FFFFFF;
                }
              }
            }
          }
        }
      }

      .modal {
        display: block;
        position: fixed;
        z-index: 25;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        overflow: auto;
        background-color: rgba(0,0,0,0.6);

        .modal-content-partner {
          background-color: #FFFFFF;
          margin: 13.5vh 35vw;
          width: 30vw;
          height: 60vh;
          padding: 5vh 0 8vh 0;
          border-radius: 2vw;
          position: relative;

          &::-webkit-scrollbar {
            display: none;
          }

          .modal-main-partner {
            position: relative;
            z-index: 3;
            margin: 1.5vh 1.5vw 0 1.5vw;
            padding: 0 2.5vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            text-align: center;
            overflow-y: auto;

            &::-webkit-scrollbar {
              width: .3vw;
            }

            &::-webkit-scrollbar-thumb {
              background: rgba(69, 120, 251, 0.5);
            }

            &::-webkit-scrollbar-track {
              margin: 7vh 0;
              background: #E5E5E5;
            }

            img {
              width: 18vw;
              margin: 3vh 0;
            }
            
            .modal-message {
              margin: 0 0 3vh 0;
              font-size: 1vw;
              font-weight: 300;
              width: 20vw;
              color: #4578FB;
            }

            .modal-title {
              margin: 0 0 3vh 0;
              font-size: 1.2vw;
              font-weight: 500;
              color: #4578FB;
            }
            
            .bold{
              font-weight: 300;
            }

            .modal-description {
              white-space: pre-line;
            }

            .modal-brands {
              width: 17.5vw;
              padding: 0 1.5vw 0 0;
              margin: 0 0 0 1.5vw;

              .brand {
                background-color: #4578FB;
                border-radius: .5vw;
                margin: 0 .4vw 1vh .4vw;
                text-align: center;
                height: 3vh;
                display: inline-flex;
                align-items: center;
              }
  
              p {
                margin: 0;
                color: #FFFFFF;
                padding: 0 .8vw;
                text-align: center;
                font-size: 1vw;
                font-weight: 300;
              }
            }
          }
  
          .modal-close {
            color: #4578FB;
            position: absolute;
            right: 2vw;
            top: 3vh;
            font-size: 2vw;
            cursor: pointer;
        
            &:hover {
              opacity: .5;
            }
          }
        }
  
        .modal-content-password {
          background-color: #FFFFFF;
          margin: 32.5vh 35vw;
          width: 30vw;
          height: 35vh;
          overflow-y: scroll;
          border-radius: 2vw;
          position: relative;
  
          &::-webkit-scrollbar {
            display: none;
          }
  
          .modal-main {
            position: relative;
            z-index: 3;
            margin: 0 6vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
  
            .modal-title {
              margin: 0 0 2vw 0;
              font-size: 1.3vw;
              font-weight: 300;
              width: 20vw;
              color: #4577FB;
            }
  
            .input-container {
              position: relative;
            }
  
            .modal-input {
              width: calc(16vw - .4vw);
              height: 4vh;
              padding-left: 2vw;
              padding-right: 2vw;
              border: 2px solid #4578FB;
              border-radius: 1vw;
              color: #4577FB;
              font-size: 1vw;
  
              &::placeholder {
                color: #4578FB;
                opacity: .7;
                font-weight: 300;
                font-size: 1.1vw;
              }
            }
  
            .password-message {
              width: 16vw;
              padding-left: 2vw;
              margin: .2vh 0 3vh 0;
              color: #4578FB;
              opacity: .8;
              text-align: left;
            }
  
            .eye-icon {
              color: #4577FB;
              opacity: .7;
              height: calc(5.5vh + .3vw - 2vh);
              font-size: 1.7vw;
              position: absolute;
              top: .5vh;
              right: 1vw;
              cursor: pointer;
              margin: 0;
            }
  
            .modal-button {
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 3;
              text-decoration: none;
              background: #4577FB;
              width: 12vw;
              height: 5vh;
              border-radius: 1vw;
              border-color: transparent;
              border-style: none;
  
              &:hover,
              &:focus,
              &:active {
                cursor: pointer;
                background: rgba(69, 119, 251, 0.8);
              }
  
              p {
                color: #FFFFFF;
                font-size: 1.2vw;
                font-weight: 300;
                margin: 0;
                cursor: pointer;
              }
            }
  
            .not-ready {
              opacity: .7;
            }
  
            .modal-button-not-ready {
              display: flex;
              justify-content: center;
              align-items: center;
              z-index: 3;
              text-decoration: none;
              background: #4577FB;
              width: 12vw;
              height: 5vh;
              border-radius: 1vw;
              border-color: transparent;
              border-style: none;
              opacity: .8;
  
              p {
                color: #FFFFFF;
                font-size: 1.2vw;
                font-weight: 300;
                margin: 0;
                cursor: pointer;
              }
            }
          }
        }
  
        .modal-content2 {
          background-color: #FFFFFF;
          margin: 27.5vh 37.5vw;
          width: 25vw;
          height: 45vh;
          overflow-y: scroll;
          border-radius: 2vw;
          position: relative;
  
          &::-webkit-scrollbar {
            display: none;
          }
  
          .modal-main2 {
            position: relative;
            z-index: 3;
            margin: 0 6vw;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
  
            .modal-title2 {
              margin: 0 0 1vw 0;
              font-size: 1.2vw;
              font-weight: 500;
              width: 18vw;
              color: #4577FB;
            }
  
            .modal-message {
              margin: 0 0 1vw 0;
              font-size: .9vw;
              font-weight: 300;
              width: 18vw;
              color: #4578FB;
            }
  
            .icon-face {
              margin: 0 0 3vh 0;
              font-size: 4.5vw;
              color: #4578FB;
            }
  
            .icon-exit {
              margin: .7vw 0 0 0;
              font-size: 2.5vw;
              color: #4578FB;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}