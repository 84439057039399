/* Web */
@media only screen and (min-width: 768px) {
  .App1 {
    display: block;
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 24;

    .modal-contact {
      display: flex;
      position: fixed;
      z-index: 25;
      justify-content: center;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      background-color: rgba(0,0,0,0.6);

      .modal-contact-content {
        background-color: #FFFFFF;
        margin: auto;
        padding: 5vh 0;
        width: 36vw;
        height: 75vh;
        border-radius: 2vw;

        &:-webkit-scrollbar {
          display: none;
        }

        #modal-close {
          position: absolute;
          z-index: 5;
          right: 34vw;
          top: 11vh;
          color: #4577FB;
          font-size: 2vw;
          padding: 0 1vw;

          &:hover,
          &:focus,
          &:active {
            color: rgba(69, 119, 251, 0.5);
            text-decoration: none;
            cursor: pointer;
          }
        }

        .modal-main {
          position: relative;
          z-index: 3;
          margin: 0 3vw;
          padding: 0 3vw;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;
          overflow-y: auto;

          .modal-title {
            margin: 1.8vw 0 0 0;
            font-size: 1.2vw;
            font-weight: 700;
            color: #4577FB;
          }

          .modal-description {
            margin: 2vh 0 4vh;
            font-size: 1vw;
            font-weight: 300;
            color: #B7B7B7;
          }

          .modal-description-1,
          .modal-description-2 {
            display: none;
          }

          #modal-name,
          #modal-email,
          #modal-phone {
            border-color: #4577FB;
            border-style: solid;
            border-radius: .9vw;
            border-width: 1.5px;
            height: 2.5vw;
            width: calc(100% - 2vw);
            color: #4577fb;
            font-size: 1vw;
            font-weight: 300;
            padding: 0 1vw;
            margin: 0 0 2vh;

            &::placeholder {
              color: #b7b7b7;
              opacity: 1;
              font-style: italic;
            }
          }

          #modal-message {
            border-color: #4577FB;
            border-style: solid;
            border-radius: .9vw;
            border-width: 1.5px;
            resize: none;
            height: 12vh;
            width: calc(100% - 2vw);
            color: #4577fb;
            font-size: 1vw;
            font-weight: 300;
            padding: .8vw 1vw;
            margin: 0 0 2vh;

            &::placeholder {
              color: #b7b7b7;
              opacity: 1;
              font-style: italic;
            }
          }

          .modal-button {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            text-decoration: none;
            background: #4577FB;
            width: 15vw;
            height: 2.7vw;
            border-radius: 15px;
            border-color: transparent;
            border-style: none;
            margin-top: 1vw;
            margin-bottom: 2vw;
            position: relative;

            &:hover,
            &:focus,
            &:active {
              cursor: pointer;
              background: rgba(69, 119, 251, 0.8);
            }
          }

          p {
            color: #FFFFFF;
            font-size: 1.1vw;
            font-weight: 500;
            margin: 0;
            cursor: pointer;
          }
        }
        
        .modal-button-not-ready {
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 3;
          text-decoration: none;
          background: #4577FB;
          width: 15vw;
          height: 2.7vw;
          border-radius: 15px;
          border-color: transparent;
          border-style: none;
          margin-top: 1vw;
          margin-bottom: 2vw;
          position: relative;
          opacity: .8;

          p {
            color: #FFFFFF;
            font-size: 1.1vw;
            font-weight: 500;
            margin: 0;
            cursor: pointer;
          }
        }
      }
    }

    .modal-alert {
      display: block;
      position: fixed;
      z-index: 25;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      overflow: auto;
      background-color: rgba(0,0,0,0.6);

      .modal-alert-content {
        background-color: #FFFFFF;
        margin: 32.5vh 35vw;
        width: 30vw;
        height: 35vh;
        overflow-y: auto;
        border-radius: 2vw;
        position: relative;

        &:-webkit-scrollbar {
          display: none;
        }

        .modal-alert-main {
          position: relative;
          z-index: 3;
          margin: 0 6vw;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .modal-alert-title {
            margin: 0 0 2vw 0;
            font-size: 1.3vw;
            font-weight: 300;
            width: 15vw;
            color: #4577FB;
          }

          .modal-alert-button {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            text-decoration: none;
            background: #4577FB;
            width: 12vw;
            height: 5vh;
            border-radius: 1vw;
            border-color: transparent;
            border-style: none;

            &:hover,
            &:focus,
            &:active {
              cursor: pointer;
              background: rgba(69, 119, 251, 0.8);
            }

            p {
              color: #FFFFFF;
              font-size: 1.2vw;
              font-weight: 300;
              margin: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .App1 {
    position: fixed;
    z-index: 25;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: #FFFFFF;

    .modal-contact {
      background-color: #FFFFFF;
      position: fixed;
      z-index: 25;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      overflow: auto;

      .modal-contact-content {
        background-color: #FFFFFF;
        margin: auto;
        width: 100%;
        height: 100%;

        &::-webkit-scrollbar {
          display: none;
        }
        
        #modal-close {
          position: absolute;
          z-index: 5;
          right: 2vw;
          top: 1vh;
          color: #4577FB;
          font-size: 10vw;
          padding: 0 3vw;

          &:hover,
          &:focus,
          &:active {
            color: rgba(69, 119, 251, 0.8);
            text-decoration: none;
            cursor: pointer;
          }
        }

        .modal-main {
          position: relative;
          z-index: 3;
          margin: 0 10vw;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .modal-title {
            margin: 0;
            font-size: 6vw;
            font-weight: 700;
            color: #4577FB;
          }

          .modal-description {
            display: none;
          }

          .modal-description-1 {
            margin: 2vh 15vw 1vh;
            font-size: 4vw;
            font-weight: 500;
            color: #C4C4C4;
            width: 70vw;
          }

          .modal-description-2 {
            margin: 0 10vw 4vh;
            font-size: 4vw;
            font-weight: 500;
            color: #C4C4C4;
          }

          #modal-name,
          #modal-phone,
          #modal-email{
            border-color: #4577FB;
            border-style: solid;
            border-radius: 2.5vw;
            border-width: 3px;
            height: 7vw;
            width: calc(100% - 4vw);
            color: #4577fb;
            font-size: 4vw;
            padding: 0 2vw;
            margin: 0 0 2vh;

            &::placeholder {
              color: #b7b7b7;
              opacity: 1;
              font-style: italic;
            }
          }

          #modal-message {
            border-color: #4577FB;
            border-style: solid;
            border-radius: 3vw;
            border-width: 3px;
            height: 15vh;
            width: calc(100% - 4vw);
            color: #4577fb;
            font-size: 4vw;
            padding: 1vw 2vw;
            margin: 0 0 2vh;
            resize: none;

            &::placeholder {
              color: #b7b7b7;
              opacity: 1;
              font-style: italic;
            }
          }

          .modal-button {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            text-decoration: none;
            background: #4577FB;
            width: 50vw;
            height: 12vw;
            border-radius: 4vw;
            border-color: transparent;
            border-style: none;
            margin-top: 2vw;

            &:hover,
            &:focus,
            &:active {
              cursor: pointer;
              background: rgba(69, 119, 251, 0.8);
            }

            p {
              color: #FFFFFF;
              font-size: 4vw;
              font-weight: 500;
              margin: 0;
              cursor: pointer;
            }
          }

          .modal-button-not-ready {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            text-decoration: none;
            background: #4577FB;
            width: 50vw;
            height: 12vw;
            border-radius: 4vw;
            border-color: transparent;
            border-style: none;
            margin-top: 2vw;
            opacity: .8;
        
            p {
              color: #FFFFFF;
              font-size: 4vw;
              font-weight: 500;
              margin: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
