/* Web */
@media only screen and (min-width: 768px) {
  .orders-container {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    .history {
      height: 87vh;
      width: 100vw;
      display: flex;
      flex-direction: row;

      .orders-list {
        height: 87vh;
        width: 58vw;
        background-color: #FFFFFF;

        .header {
          height: 9vh;
          width: 55.3vw;
          margin-left: 2.2vw;
          margin-top: 4vh;
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
              color: #4577FB;
              font-size: 3.5vh;
            }

            .text {
              margin: 0 0 0 .7vw;
              width: 100%;
              color: #4578FB;
              font-size: 1vw;
              font-weight: 500;
            }
          }

          .subtitle {
            color: #4578FB;
            font-weight: 300;
            font-size: 1vw;
            margin: 1vh 0 0 1.8vw;
          }
        }

        .list {
          height: 72.5vh;
          width: 50vw;
          margin-left: 4vw;
          overflow-y: auto;
          padding: 0 3vw 1.5vh 0;

          &::-webkit-scrollbar {
            width: .4vw;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(69, 120, 251, 0.5);
          }

          &::-webkit-scrollbar-track {
            margin-bottom: 3vh;
            background: #E5E5E5;
          }

          .element {
            height: 9vh;
            width: 48vw;
            margin-bottom: 1.5vh;
            border-radius: 2vh;
            background-color: #FFFFFF;
            display: flex;
            flex-direction: row;
            padding: 0.5vh 1vw 0.5vh 1vw;
            border: 2px solid #4578FB ;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, .1);

            .sector {
              height: 9vh;
              width: 24vw;
              display: flex;
              flex-direction: column;
              justify-content: center;

              .text {
                color: #4578FB;
                font-size: 2vh;
                font-weight: 500;
                margin: .5vh 0 .5vh 0;
              }

              .click-text {
                cursor: pointer;
              }

              .purchase-id {
                font-weight: 700;
              }

              .unselected {
                color: #B7B7B7;
                font-weight: 300;
              }

              .hide {
                color: #FFFFFF;
              }
            }

            .left {
              align-items: flex-start;
              margin-left: 1vw;
            }

            .right {
              align-items: flex-end;
              margin-right: 1vw;
            }
          }

          .not-click {
            border: 1.5px solid rgba(69, 120, 251, .17);
            cursor: pointer;
          }
        }
      }

      .order-details {
        height: 87vh;
        width: 42vw;
        display: flex;
        flex-direction: column;
        box-shadow: -10px 4px 10px rgba(0, 0, 0, .15);

        .details {
          height: 67vh;
          width: 39vw;
          background-color: #FFFFFF;
          overflow-y: auto;
          margin: 0 1vw;
          padding: 1.5vh 2vw 0 2vw;

          &::-webkit-scrollbar {
            width: .4vw;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(69, 120, 251, 0.5);
          }

          &::-webkit-scrollbar-track {
            margin-top: 3vh;
            margin-bottom: 3vh;
            background: #E5E5E5;
          }

          .partner {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 36vw;
            height: auto;
            padding-bottom: 3vh;

            .header {
              width: 36vw;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              padding-top: 2vh;
              border-top: 1px dashed rgba(126, 126, 126, 0.5);

              .name {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                height: 5vh;

                .icon {
                  color: #4577FB;
                  font-size: 3.5vh;
                }

                .text {
                  margin: 0 0 0 .7vw;
                  width: 100%;
                  color: #4578FB;
                  font-size: 1vw;
                  font-weight: 500; 
                }
              }

              .total {
                margin: 0;
                color: #4578FB;
                font-weight: 500;
                font-size: 1vw;
                padding: 0 1vw 0 0;
              }
            }

            .none-border {
              border: none;
            }
          }
        }

        .products {
          width: 34vw;
          display: flex;
          flex-direction: column;
          border-radius: 1.2vw;
          align-items: center;
          border: 1px solid rgba(69, 120, 251, .17);
          box-shadow: 0px 2px 2px rgba(0, 0, 0, .1);
          padding: 0 1vw;
          margin: 1vh 0 0 0;

          .product {
            height: 15vh;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            position: relative;
            border-top: .15vw solid #F2F2F2;

            .discount {
              position: absolute;
              top: 1.5vh;
              left: .5vw;
              width: 1.6vw;
            }

            .image {
              width: 5.5vw;
            }

            .product-data {
              display: flex;
              width: 10vw;
              height: 7vh;
              flex-direction: column;
              justify-content: center;
              align-items: flex-start;
              justify-content: space-between;
              margin: 0 2.6vw 0 .8vw;

              .text {
                margin: 0;
                font-weight: 300;
                font-size: .9vw;
                color: #7E7E7E;
              }

              .name {
                width: 100%;
                font-weight: 300;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
              } 
            }

            .long-data {
              height: 10vh;
            }

            .quatity-container {
              width: 6.7vw;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              margin-right: 2vw;

              .quantity-circle {
                border: 1px solid #E5E5E5;
                box-sizing: border-box;
                box-shadow: inset 0px -2px 0px rgba(0, 0, 0, 0.25);
                border-radius: 10px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 2.7vw;
                height: 2.7vw;
                margin: 0 .5vw;
                color: #4578FB;
                font-size: 1.1vw;
                text-align: center;

                .number {
                  color: #4578FB;
                  font-size: 1.1vw;
                  margin: 0;
                }
              }
            }

            .values {
              height: 7vh;
              width: 4.7vw;
              padding: 0 1vw 0 0;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: space-between;
              position: relative;

              .text {
                margin: 0;
                font-weight: 700;
                font-size: .9vw;
                color: #7E7E7E;
              }
            
              .slim {
                font-weight: 300;
              }
            }
          }

          .none-border {
            border: none;
          }
        }

        .credits-container {
          position: absolute;
          width: 42vw;
          bottom: 10vh;
          right: 0;
          box-shadow: 0px -10px 15px rgba(0, 0, 0, 0.1);
          background-color: #FFFFFF;
          display: flex;
          align-items: center;
          justify-content: center;
  
          .info-container {
            width: 36vw;
            height: 5.4vh;
            padding: 2.5vh 0;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-bottom: .1vh solid #DADADA;
            position: relative;
          }
  
          .left {
            width: 60%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-between;
  
            .gray {
              color: #7E7E7E;
              font-size: 1.05vw;
              font-weight: 500;
              margin: 0;
              text-align: left;
            }
  
            .blue {
              color: #4578FB;
              font-size: 1.05vw;
              font-weight: 500;
              margin: 0;
              text-align: left;
              display: flex;
              flex-direction: row;
              align-items: center;
              cursor: pointer;
  
              .icon {
                font-size: 1.1vw;
                margin: 0 0 0 .5vw;
              }
            }
          }
  
          .right {
            width: 60%;
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: space-between;
  
            .info-loading {
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              justify-content: center;
  
              .spinner {
                color: #4578FB;
                width: 1.5vw !important;
                height: 1.5vw !important;
              }
            }  
  
            .gray {
              color: #7E7E7E;
              font-size: 1.05vw;
              font-weight: 500;
              margin: 0;
              text-align: right;
            }
  
            .blue {
              color: #4578FB;
              font-size: 1.05vw;
              font-weight: 500;
              margin: 0;
              text-align: right;
            }
          }
        }

        .shadow {
          box-shadow: 0px -10px 15px rgba(0, 0, 0, .15);
        }

        .price {
          position: absolute;
          bottom: 0;
          height: 8vh;
          width: 36vw;
          padding: 1vh 3vw;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          background-color: #FFFFFF;
          z-index: 1;

          .text {
            color: #7E7E7E;
            font-weight: 300;
            font-size: .9vw;
            margin: 0;
          }

          .number {
            color: #4578FB;
            font-weight: 500;
            font-size: 1.3vw;
          }
          
          .button {
            height: 4.5vh;
            width: 17vw;
            margin-right: 3vw;
            display: none;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #4578FB;
            border-radius: 1.3vh;
            border: 0px;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
              cursor: pointer;
              background: rgba(69, 119, 251, 0.8);
            }

            .text {
              color: #FFFFFF;
              font-size: 2.2vh;
              font-weight: 500;
              margin: 0 0 0 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
