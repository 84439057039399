/* Web */
@media only screen and (min-width: 768px) {
  .modal-credits {
    display: flex;
    position: fixed;
    z-index: 25;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.6);
    
    .content {
      background-color: #FFFFFF;
      margin: auto;
      padding: 5vh 0;
      width: 36vw;
      height: 75vh;
      border-radius: 2vw;

      &:-webkit-scrollbar {
        display: none;
      }

      #modal-close {
        position: absolute;
        z-index: 5;
        right: 34vw;
        top: 11vh;
        color: #4577FB;
        font-size: 2vw;
        padding: 0 1vw;

        &:hover,
        &:focus,
        &:active
        {
          color: rgba(69, 119, 251, 0.5);
          text-decoration: none;
          cursor: pointer;
        }
      }

      .main {
        position: relative;
        z-index: 3;
        margin: 0 4vw 0 4vw;
        height: 100%;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        text-align: center;

        .title {
          margin: 1.3vw 0 0 0;
          font-size: 1.3vw;
          font-weight: 500;
          color: #4577FB;
          justify-self: flex-start;
        }

        .info-container {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          position: relative;
          margin: 4vh 0;

          .icon {
            color: #4577FB;
            font-size: 1.8vw;
            margin: 0 1vw 0 0;
          }

          .info {
            color: #4577FB;
            font-size: 1vw;
            font-weight: 300;
            margin: 0;
            width: 77%;
          }

          .info-from-cart {
            color: #4577FB;
            font-size: 1vw;
            font-weight: 300;
            margin: 0;
            width: 50%;
          }
        }

        .credits-container {
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          position: relative;
          overflow-y: auto;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: #F1F1F150;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(69, 120, 251, .15);
            border-radius: 5px;
          }

          .credit-container {
            width: 90%;
            height: 26vh;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            margin: 0 0 2vh 0;
            border: 1px solid rgba(69, 120, 251, 0.17);
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);
            border-radius: 15px;

            .logo {
              width: 22vh;
            }

            .amount {
              color: #4577FB;
              font-size: 1.2vw;
              font-weight: 500;
              margin: 0 0 1vh 0;
            }

            .info {
              color: #B7B7B7;
              width: 100%;
              font-size: .8vw;
              font-weight: 300;
              margin: 0;
            }

            .bold {
              font-weight: 500;
            }
          }
        }

        .none-credits-container {
          width: 100%;
          height: 90%;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding: 0 0 10% 0;

          .icon {
            font-size: 7vw;
            color: #4577FB;
            margin: 0 0 2vh 0;
          }

          .text1 {
            font-size: 1.2vw;
            font-weight: 500;
            color: #4577FB;
            margin: 0;
            width: 70%;
          }

          .text2 {
            font-size: 1.2vw;
            font-weight: 500;
            color: #4577FB;
            margin: 0;
            width: 50%;
          }
        }
      }    
    }
  }
}
