/* Web */
@media only screen and (min-width: 768px) {
  .user-saving-modal {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .5);
    z-index: 15;

    .modal-content {
      position: absolute;
      bottom: 20vh;
      left: 32.5vw;
      width: 35vw;
      height: 56vh;
      background-color: white;
      border-radius: 2vw;
      z-index: 16;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 5vh 0;

      .close-icon {
        color: #4578FB;
        position: absolute;
        right: 2vw;
        top: 3vh;
        font-size: 2vw;
        cursor: pointer;

        &:hover {
          opacity: .5;
        }
      }

      .title {
        color: #4578FB;
        font-size: 1.2vw;
        font-weight: 500;
        margin: 2vh 0 0 0;
        position: relative;
      }

      .partner {
        width: 100%;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .name {
          color: #7E7E7E;
          font-size: 1vw;
          font-weight: 500;
          margin: 3vh 0 2vh 0;
          position: relative;
        }

        .information {
          width: calc(80% - 1.5vw - 5px);
          max-height: 39vh;
          position: relative;
          overflow-y: auto;
          padding: 0 1.5vw 0 0;

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: #F1F1F150;
            border-radius: 5px;
          }

          &::-webkit-scrollbar-thumb {
            background: rgba(69, 120, 251, .15);
            border-radius: 5px;
          }

          .promotions-container {
            width: 100%;
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .none-border {
              border: none !important;
            }
            
            .promotions {
              width: 100%;
              position: relative;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              padding: 1vh 0 0 0;
              border-top: 0.5px solid #DADADA;

              .info1 {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 0 0 1vh 0;

                .promotion {
                  background-color: #4578FB;
                  color: #FFFFFF;
                  border-radius: 6px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: .9vw;
                  font-weight: 300;
                  padding: .3vw 1vw;
                  width: fit-content;
                }

                .save {
                  color: #7E7E7E;
                  font-size: .8vw;
                  font-weight: 300;
                  margin: 0;
                }
              }

              .info2{
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                margin: 0 0 1vh 0;

                .long-promotion {
                  color: #7E7E7E;
                  font-size: .8vw;
                  font-weight: 300;
                  margin: 0;
                  width: 75%;
                }

                .mount {
                  color: #4578FB;
                  font-size: 1vw;
                  font-weight: 500;
                  margin: 0;
                  width: 25%;
                  text-align: right;
                }
              }
            }
          }
        }
      }

      .accept-button {
        position: absolute;
        bottom: 4vh;
        left: 30%;
        background-color: #4578FB;
        color: #FFFFFF;
        font-size: 1.1vw;
        font-weight: 300;
        width: 40%;
        height: 4vh;
        border: none;
        border-radius: .5vw;
        cursor: pointer;
      }
    }
  }
}
