/* Web */
@media only screen and (min-width: 768px) {
  .login-container {
    height: 100vh;
    background: linear-gradient(105.89deg, #4577FB 0%, #4577FB 0%, #28C3FF 76.56%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .landing-link {
      position: relative;
      cursor: pointer;
      margin-bottom: 5vw;

      .logo {
        width: 24.7vw;
      }
    }

    .login-label {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 35vw;
      padding-left: 2vw;

      .icon {
        color: #FFFFFF;
        font-size: 4.5vh;
      }

      .message {
        font-size: 1.5vw;
        font-weight: 500;
        color: #FFFFFF;
      }
    }

    *:focus {
      outline: none;
    }

    .login-email {
      width: 30.05vw;
      height: 5.5vh;
      background-color: transparent;
      border: 2px solid #FFFFFF;
      border-radius: 1.2vw;
      font-size: 1.3vw;
      color: #FFFFFF;
      padding: 0 2vw .5vh 2vw;
      margin-bottom: 1.3vw;
      position: relative;
  
      // Background color
      &:-webkit-autofill {
        transition: background-color 5000s ease-in-out 0s;
      }
        
      // Font styles
      &:-webkit-autofill::first-line {
        font-family: 'museo-sans';
        font-size: 1.3vw;
        color: #FFFFFF;
      }

      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
        font-weight: 300;
        font-style: italic;
        font-size: 1.1vw;
      }    
    }

    .input-container {
      width: calc(32.05vw + .3vw + 2vw);
      height: calc(5.5vh + .3vw);
      position: relative;
      margin-bottom: 1vh;

      .login-password {
        width: 29.05vw;
        height: 5.5vh;
        background-color: transparent;
        border: 2px solid #FFFFFF;
        border-radius: 1.2vw;
        font-size: 1.3vw;
        color: #FFFFFF;
        padding: 0 3vw 0.5vh 2vw;
        position: relative;
        margin: 0;
    
        // Background color
        &:-webkit-autofill {
          transition: background-color 5000s ease-in-out 0s;
        }
          
        // Font styles
        &:-webkit-autofill::first-line {
          font-family: 'museo-sans';
          font-size: 1.3vw;
          color: #FFFFFF;
        }

        &::placeholder {
          color: rgba(255, 255, 255, 0.7);
          font-weight: 300;
          font-style: italic;
          font-size: 1.1vw;
        }
      }

      .eye-icon {
        color: #FFFFFF;
        height: calc(5.5vh + .3vw - 2vh);
        position: absolute;
        top: 0;
        right: 0;
        padding: 1.8% 3% 0 0;
        font-size: 1.7vw;
        cursor: pointer;
        margin: 0;
      }
    }

    .forget-container {
      display: flex;
      flex-direction: row;
      width: 35vw;
      padding-left: 4vw;
      justify-content: left;
      align-items: center;
      position: relative;
      margin-bottom: 1vh;

      .icon {
        position: relative;
        color: #FFFFFF;
        font-size: 2vw;
        cursor: default;
        margin-bottom: .3vh;
      }

      .message {
        margin: 0 0 .3vh 0;
        color: #FFFFFF;
        position: relative;
        font-size: 1.1vw;
        cursor: pointer;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .confirm-button {
      width: 34.35vw;
      height: calc(6vh + .3vw);
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.5);
      font-size: 1.3vw;
      font-weight: 500;
      color: #4578FB;
      border-radius: 1.2vw;
      border: none;
      margin: 0;
      position: relative;
      cursor: pointer;
    }

    .confirm-button-white {
      width: 34.35vw;
      height: calc(6vh + .3vw);
      justify-content: center;
      background-color: rgba(255, 255, 255, 1);
      font-size: 1.3vw;
      font-weight: 500;
      color: #4578FB;
      border-radius: 1.2vw;
      border: none;
      margin: 0;
      position: relative;
      cursor: pointer;
    }

    .signup-link-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      margin-top: 2vw;

      .message {
        color: #FFFFFF;
        font-size: 1.1vw;
        margin: 0;
      }

      .link {
        color: #FFFFFF;
        margin: 1vw 0 0 0;
        font-size: 1.1vw;
        text-decoration: underline;
        position: relative;
        cursor: pointer;
      }
    }

    .modal {
      display: block;
      position: fixed;
      z-index: 25;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100vh;
      padding: 0;
      overflow: auto;
      background-color: rgba(0,0,0,0.6);

      .modal-content {
        background-color: #FFFFFF;
        margin: 32.5vh 35vw;
        width: 30vw;
        height: 35vh;
        padding: 0;
        overflow-y: scroll;
        border-radius: 2vw;
        position: relative;

        &::-webkit-scrollbar {
          display: none;
        }

        .modal-main {
          position: relative;
          z-index: 3;
          margin: 0 6vw;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          text-align: center;

          .modal-title {
            margin: 0 0 2vh 0;
            font-size: 1.3vw;
            font-weight: 300;
            width: 15vw;
            color: #4577FB;
          }

          .modal-title2 {
            margin: 0 0 2vh 0;
            font-size: 1.3vw;
            font-weight: 500;
            width: 20vw;
            color: #4577FB;
          }

          .modal-button {
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 3;
            text-decoration: none;
            background: #4577FB;
            width: 12vw;
            height: 5vh;
            margin: 2vh 0 0 0;
            border-radius: 1vw;
            border-color: transparent;
            border-style: none;

            &:hover,
            &:focus,
            &:active {
              cursor: pointer;
              background: rgba(69, 119, 251, 0.8);
            }

            p {
              color: #FFFFFF;
              font-size: 1.2vw;
              font-weight: 300;
              margin: 0;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
