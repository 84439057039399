/* Web */
@media only screen and (min-width: 768px) {
  .modal-user {
    display: flex;
    position: fixed;
    z-index: 25;
    justify-content: center;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(0,0,0,0.6);
    
    .content {
      background-color: #FFFFFF;
      margin: auto;
      padding: 5vh 0;
      width: 36vw;
      height: 75vh;
      border-radius: 2vw;

      &:-webkit-scrollbar {
        display: none;
      }

      #modal-close {
        position: absolute;
        z-index: 5;
        right: 34vw;
        top: 11vh;
        color: #4577FB;
        font-size: 2vw;
        padding: 0 1vw;

        &:hover,
        &:focus,
        &:active
        {
          color: rgba(69, 119, 251, 0.5);
          text-decoration: none;
          cursor: pointer;
        }
      }

      .main {
        position: relative;
        z-index: 3;
        margin: 0 4vw 0 4vw;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;

        .title {
          margin: 1.8vw 0 0 0;
          font-size: 1.2vw;
          font-weight: 700;
          color: #4577FB;
          justify-self: flex-start;
        }

        .form-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 5vh;
          height: 55vh;
          overflow-y: auto;
          margin-bottom: 2vh;
          padding: 0 1vw 2vh 1vw;

          .section {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .title-section {
              display: flex;
              flex-direction: row;
              align-items: center;
              margin: 4vh 0 0 2vh;

              .icon {
                color: #4577FB;
                font-size: 2.5vh;
              }

              .text {
                font-size: 2vh;
                font-weight: 500;
                margin: 0;
                color: #4577FB;
              }

              .clickable-text {
                cursor: pointer;
              }
            }

            .button {
              cursor: pointer;
              &:hover,
              &:focus,
              &:active {
                opacity: .5;
              }
            }

            .label {
              color: #b7b7b7;
              font-weight: normal;
              font-style: italic;
              font-size: 1.8vh;
              font-weight: 300;
              margin: 1vh 0 .3vh calc(2.5vh + 1vw);
            }

            .input-container {
              display: flex;
              flex-direction: row;
              justify-content: flex-start;
              align-items: center;
              border: 1px solid #b7b7b7;
              border-radius: 2vh;
              width: 25vw;
              margin-left: 2.5vh;
              height: 6vh;

              .field {
                background-color: transparent;
                border-color: transparent;
                color: #4578FB;
                font-size: 1.9vh;
                font-weight: 400;
                font-style: italic;
                margin: 0 1vw 0 1vw;
                width: 23vw;
                opacity: .7;
              }

              .field-password {
                background-color: transparent;
                border-color: transparent;
                color: #4578FB;
                font-size: 1.9vh;
                font-weight: 400;
                font-style: italic;
                margin: 0 0.5vw 0 1vw;
                width: 21vw;
                opacity: .7;
              }

              .eye-icon {
                color: rgba(69, 120, 251, 0.7);
                font-size: 1.5vw;
                margin-right: 1vw;
              }
            }
          }
        }

        .save-button {
          justify-self: flex-end;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #4577FB;
          border-radius: 1.5vh;
          border-color: transparent;
          height: 5vh;
          width: 17vw;
          cursor: pointer;

          &:hover,
          &:focus,
          &:active {
            cursor: pointer;
            background: rgba(69, 119, 251, 0.8);
          }

          .text {
            color: #FFFFFF;
            font-weight: 500;
            font-size: 2.1vh;
            cursor: pointer;
          }
        }
      }    
    }
  }
}
