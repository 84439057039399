/* Web */
@media only screen and (min-width: 768px) {
  .wings-div {
    position: absolute;
    width: 100%;
    height: 100%;

    .wings-web-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 64vw;
    }
  
    .wings-web-bot {
      position: absolute;
      bottom: 0;
      right: 0;
      width: 67vw;
    }
  
    .wings-mobile-top {
      display: none;
    }
  
    .wings-mobile-bot {
      display: none;
    }
  }
}

/* Mobile */
@media only screen and (max-width: 767px) {
  .wings-div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  
    .wings-mobile-top {
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 50%;
    }
  
    .wings-mobile-bot {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      height: 50%;
    }

    .wings-web-top {
      display: none;
    }
    
    .wings-web-bot {
      display: none;
    }
  }
}
